const plenaryHall = ({ plenaryHall, dispatch, onPublishChange }) => {
  const publishButton = plenaryHall.id
    ? plenaryHall.published_at
      ? {
          label: 'Unpublish',
          color: 'warning',
          tooltip: 'By unpublishing, you will not be able to see the public page anymore',
          icon: 'visibility_off',
          onClick: () => onPublishChange(false, plenaryHall)
        }
      : {
          label: 'Publish',
          color: 'success',
          tooltip: 'Be sure to have saved before publishing',
          icon: 'rocket_launch',
          onClick: () => onPublishChange(true, plenaryHall)
        }
    : null
  return {
    id: 'plenary-hall-form',
    title: 'Event space Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: 'General',
        type: 'accordion',
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            label: 'Slug',
            name: 'slug',
            slugify: true,
            required: !!plenaryHall.id,
            hidden: !plenaryHall.id,
            validationType: 'string',
            validations: plenaryHall.id
              ? [
                  {
                    type: 'slug',
                    params: [
                      'plenary-halls',
                      'This slug is not available',
                      plenaryHall.slug,
                      dispatch
                    ]
                  },
                  {
                    type: 'required',
                    params: ['This field is required']
                  }
                ]
              : null
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: null
          },
          {
            type: 'address',
            name: 'address',
            label: 'Address',
            required: true,
            validationType: 'string',
            grid: { xs: 12, md: 6 },
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'select',
            name: 'town',
            label: 'Town / Venue',
            labelProp: 'name',
            valueProp: 'id',
            required: true,
            groupBy: (o) => o.entity,
            optionsGetter: {
              endpoints: [
                { name: 'Towns', entity: 'town', prefix: 'town' },
                { name: 'Venues', entity: 'allInVenue', prefix: 'allInVenue' }
              ]
            },
            grid: { xs: 12, md: 6 },
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              }
            ]
          },
          {
            type: 'number',
            label: 'Capacity',
            name: 'capacity',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 6 }
          },
          {
            type: 'text',
            label: 'Phone Number',
            name: 'phone_number',
            validationType: 'text',
            validations: null,
            grid: { xs: 12, md: 6 }
          },
          {
            type: 'number',
            label: 'Min price',
            name: 'price_min',
            validationType: 'string',
            suffix: '€',
            validations: null,
            grid: { xs: 12, md: 3 }
          },
          {
            type: 'number',
            label: 'Max price',
            name: 'price_max',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 3 }
          },
          {
            type: 'number',
            label: 'Normal price',
            name: 'normal_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 3 }
          },
          {
            type: 'number',
            label: 'Weekday price',
            name: 'weekday_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 3 }
          },
          {
            type: 'select',
            name: 'categories',
            label: 'Categories of event spaces *',
            multiple: true,
            optionsGetter: {
              endpoints: [
                {
                  name: 'categories',
                  entity: 'locationCategories',
                  id: 'plenary_hall'
                }
              ]
            },
            labelProp: 'name',
            valueProp: 'id',
            validationType: 'array',
            validations: [
              {
                type: 'nullable',
                params: []
              },
              {
                type: 'min',
                params: [1, 'You must select at least one category']
              }
            ],
            grid: { xs: 12, md: 6, mr: { xs: 0, md: 12 } }
          },
          {
            type: 'switch',
            name: 'available',
            label: 'Is the space available?',
            validationType: 'boolean',
            validations: null
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            required: true,
            placeholder: 'Write a description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'max',
                params: [2000, 'Description must be less than 2000 characters']
              }
            ]
          }
        ]
      },
      {
        id: 'features',
        title: 'Features',
        type: 'accordion',
        fields: [
          {
            type: 'checkboxgroup',
            name: 'features',
            label: '',
            optionsGetter: {
              endpoints: [
                {
                  name: 'feature',
                  entity: 'locationFeatures',
                  id: 'plenary_hall',
                  formatGroup: (options) => {
                    return options.reduce((acc, curr) => {
                      const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                      if (alreadyInAcc) {
                        alreadyInAcc.controls.push({
                          name: curr.id,
                          label: curr.name
                        })
                      } else {
                        acc.push({
                          label: curr.subtype,
                          controls: [{ name: curr.id, label: curr.name }]
                        })
                      }
                      return acc
                    }, [])
                  }
                }
              ]
            }
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default plenaryHall
