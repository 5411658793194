import { smartwayApi } from 'services/api'
import { redirect } from 'react-router-dom'
import { setAuthenticated, setProfile, setGroups } from 'store/reducers/AuthSlice'
import { saveParticipantPreferences } from 'utils/offsites'
export const loginAction =
  (dispatch) =>
  async ({ request }) => {
    try {
      let nextUrl = new URL(request.url).searchParams.get('next')
      const payload = await request.json()
      const loginResponse = await dispatch(
        smartwayApi.endpoints.login.initiate(payload, { forceRefetch: true })
      )
      if (loginResponse.error) {
        throw loginResponse.error
      }
      dispatch(setAuthenticated(true))
      const { email } = loginResponse?.data || ''
      const profileResponse = await dispatch(
        smartwayApi.endpoints.fetchBaseProfile.initiate(null, {
          forceRefetch: true
        })
      )

      if (profileResponse && profileResponse.data && !profileResponse.data.unauthorized) {
        const { data } = profileResponse
        dispatch(setProfile(data))
        if (data.is_staff) {
          const groupsResponse = await dispatch(
            smartwayApi.endpoints.fetchGroups.initiate(null, {
              forceRefetch: true
            })
          )
          if (groupsResponse && groupsResponse.data && !groupsResponse.data.unauthorized) {
            const { data } = groupsResponse
            dispatch(setGroups(data.results))
          }
        }
      }

      const nextPage = nextUrl
        ? `${nextUrl}${nextUrl.includes('?') ? '&' : '?'}email=${payload.email}`
        : `/dashboard/profile/?email=${payload.email}`
      return redirect(nextPage)
    } catch (error) {
      console.error(error)
      dispatch(setAuthenticated(false))
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }

export const updateProfileAction =
  (dispatch) =>
  async ({ request }) => {
    try {
      const payload = await request.json()

      const intent = payload.intent
      let response
      switch (intent) {
        case 'refresh-data':
          response = {
            data: {}
          }
          break
        case 'change-password':
          response = await dispatch(smartwayApi.endpoints.changePassword.initiate(payload))
          break
        case 'basic-info':
          response = await dispatch(
            smartwayApi.endpoints.updateEntity.initiate(
              { entity: 'profile', id: 'me', ...payload },
              { forceRefetch: true }
            )
          )
          break
        case 'delete':
          response = await dispatch(
            smartwayApi.endpoints.deleteEntity.initiate({
              entity: 'profile',
              id: 'me'
            })
          )
          if (response) {
            await dispatch(smartwayApi.endpoints.logout.initiate())
            return redirect('/authentication/sign-in?deleted=true')
          }
          return {
            error: 'There was an error!',
            fullError: ''
          }
        case 'offsite-participation-change':
          const responses = await saveParticipantPreferences({ dispatch, payload, context: 'profile' })
          response = responses.every((r) => !!r.data) ? responses[0] : {}
          break
        default:
          break
      }

      if (response.data) {
        return {
          intent,
          success: true,
          ...response.data
        }
      }
      if (response.error) {
        return { intent, error: response.error }
      }
    } catch (error) {
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }

export const resetPasswordAction =
  (dispatch) =>
  async ({ request }) => {
    try {
      const payload = await request.json()
      const response = await dispatch(smartwayApi.endpoints.resetPassword.initiate(payload))
      if (response.data) {
        return {
          success: true
        }
      }
      if (response.error) {
        console.log(response.error)
        return { error: 'Firebase error', fullError: response.error }
      }
    } catch (error) {
      console.log('catch', error)
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }

export const registerAction =
  (dispatch) =>
  async ({ request }) => {
    let arrayMessage = []

    try {
      const payload = await request.json()
      const response = await dispatch(smartwayApi.endpoints.register.initiate(payload))

      if (response.data) {
        const { success, error } = response.data
        if (success) {
          return {
            success: true,
            ...response.data
          }
        }
        return {
          error: 'Registration failed',
          fullError: error || 'Unknown error during registration'
        }
      } else {
        const { error } = response
        if (error && typeof error === 'object') {
          Object.keys(error).forEach((k) => {
            if (k !== 'message') {
              arrayMessage.push(`${k}: ${error[k]}`)
            }
          })
        }
        return {
          error: error?.message || 'There was an error!',
          fullError: arrayMessage.join('\n')
        }
      }
    } catch (error) {
      if (error.error) {
        Object.keys(error.error).forEach((k) => {
          if (k !== 'message') {
            arrayMessage.push(`${k}: ${error[k]}`)
          }
        })
      }
      return {
        error: error.error?.message || 'There was an error!',
        fullError: arrayMessage.join('\n')
      }
    }
  }
export const confirmRegistrationAction =
  (dispatch) =>
  async ({ request }) => {
    try {
      const payload = await request.json()
      const response = await dispatch(smartwayApi.endpoints.confirmRegistration.initiate(payload))
      if (response.data && response.data.success) {
        if (payload.redirect_url) {
          return redirect(payload.redirect_url)
        }
        return {
          success: true
        }
      }
      return { error: response.data.error }
    } catch (error) {
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }
