import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'
import { notify } from 'store/reducers/UISlice'

import TeamDialog from 'components/Dialogs/TeamDialog'
import LinkDialog from 'components/Dialogs/LinkDialog'
import TeamCard from './TeamCard'
import LinkCard from './LinkCard'
import { WorkshopSkeleton } from './Skeletons'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const AddCard = ({ title, icon, onAdd }) => {
  return (
    <Card sx={{ height: '100%', border: '1px dashed #7b809a' }}>
      <MDBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
        minHeight="180px"
        minWidth="120px"
        sx={{ cursor: 'pointer' }}
        onClick={onAdd}>
        <MDTypography variant="body2">{title}</MDTypography>
        <MDBox>
          <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
            {icon}
          </Icon>
        </MDBox>
      </MDBox>
    </Card>
  )
}

const ActivityWorkshopCustomization = ({ offsiteId, values, onCreateActivity, onUpdateSlot }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [offsiteParticipants, setOffsiteParticipants] = useState([])
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [teams, setTeams] = useState([])
  const [editingTeam, setEditingTeam] = useState({})
  const [editingLink, setEditingLink] = useState({})
  const [teamDialogOpen, setTeamDialogOpen] = useState(false)
  const [linkDialogOpen, setLinkDialogOpen] = useState(false)
  const [activity, setActivity] = useState(values)

  const handleAddTeam = () => {
    setTeamDialogOpen(true)
  }
  const handleAddLink = () => {
    setLinkDialogOpen(true)
  }

  const getUniqueValues = (arr) => {
    return arr.filter((value, index, originalArray) => originalArray.indexOf(value) === index)
  }

  const notifyFeedback = ({ success }) => {
    if (success) {
      dispatch(
        notify({
          title: 'Well done!',
          message: 'Agenda was updated successfully',
          type: 'success',
          icon: 'done'
        })
      )
    } else {
      dispatch(
        notify({
          title: 'Ops!',
          message: 'There was an error updating the agenda',
          type: 'error',
          icon: 'error'
        })
      )
    }
  }
  // ACTIVITY 

  const handleSaveActivity = async (activityValues) => {
    try {
      setIsLoading(true)
      const response = await dispatch(
        smartwayApi.endpoints.updateEntity.initiate({
          entity: 'activities',
          id: activity.id,
          ...activityValues
        })
      )
      if (response && response.data) {
        setActivity(response.data)
        notifyFeedback({ success: true })
      }
    } catch (error) {
      console.warn(error)
      notifyFeedback({ success: false })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchActivity = async (activityId) => {
    try {
      setIsLoading(true)
      const response = await dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: 'activities', id: activityId || activity.id }))
      if (response && response.data) {
        setActivity(response.data)
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  // TEAM
  const handleSaveTeam = async (teamValues) => {
    const endpoint = editingTeam.id ? 'updateEntity' : 'createEntity'
    try {
      setIsLoading(true)
      const response = await dispatch(
        smartwayApi.endpoints[endpoint].initiate({
          entity: 'activityGroup',
          ...(editingTeam.id ? { id: editingTeam.id } : {}),
          ...teamValues
        })
      )
      if (response && response.data) {
        const groups = getUniqueValues([...(activity.groups || []), response.data.id])
        const payload = { groups }
        if (activity.id) {
          await handleSaveActivity(payload)
          onUpdateSlot(payload)
          await fetchActivity()
        } else {
          const newActivity = await onCreateActivity(payload)
          await fetchActivity(newActivity.id)
        }
      }
    } catch (error) {
      console.warn(error)
      notifyFeedback({ success: false })
    } finally {
      setEditingTeam({})
      setTeamDialogOpen(false)
      setIsLoading(false)
      notifyFeedback({ success: true })
    }
  }

  const handleEditTeam = (team) => {
    setEditingTeam(team)
    setTeamDialogOpen(true)
  }

  const handleDeleteTeam = async (team) => {
    try {
      setIsLoading(true)
      const response = await dispatch(
        smartwayApi.endpoints.deleteEntity.initiate({
          entity: 'activityGroup',
          id: team.id
        })
      )
      if (response && response.data) {
        const groups = getUniqueValues(activity?.groups.filter((id) => id !== team.id))
        let participants = null
        if (team.participants && !!team.participants.length) {
          participants = activity.participants.filter((id) => !team.participants.includes(id))
        }
        const payload = { groups, ...(participants ? { participants } : {}) }
        await handleSaveActivity(payload)
        onUpdateSlot(payload)
        await fetchActivity()
      }
    } catch (error) {
      console.warn(error)
      notifyFeedback({ success: false })
    } finally {
      setIsLoading(false)
      notifyFeedback({ success: true })
    }
  }

  // LINK
  const handleSaveLink = async (linkValues) => {
    const endpoint = editingLink.id ? 'updateEntity' : 'createEntity'
    try {
      setIsLoading(true)
      const response = await dispatch(
        smartwayApi.endpoints[endpoint].initiate({
          entity: 'link',
          ...(editingLink.id ? { id: editingLink.id } : {}),
          ...linkValues
        })
      )
      if (response && response.data) {
        if (!editingLink.id) {
          const links = getUniqueValues([
            ...(activity?.links && !!activity.links.length ? activity.links.map((link) => link.id) : []),
            response.data.id
          ])
          await handleSaveActivity({ links })
          onUpdateSlot({ links })
          await fetchActivity()
        } else {
          fetchActivity()
        }
      }
    } catch (error) {
      console.warn(error)
      notifyFeedback({ success: false })
    } finally {
      setEditingLink({})
      setLinkDialogOpen(false)
      setIsLoading(false)
      notifyFeedback({ success: true })
    }
  }

  const handleEditLink = (link) => {
    setEditingLink(link)
    setLinkDialogOpen(true)
  }

  const handleDeleteLink = async (link) => {
    try {
      setIsLoading(true)
      const response = await dispatch(
        smartwayApi.endpoints.deleteEntity.initiate({
          entity: 'link',
          id: link.id
        })
      )
      if (response && response.data) {
        fetchActivity()
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (activity?.groups && !!activity.groups.length) {
        const getTeams = async () => {
          const promises = []
          activity.groups.forEach((id) => {
            promises.push(
              dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: 'activityGroup', id }))
            )
          })
          const results = await Promise.all(promises)
          if (results && results.length) {
            const mappedResults = results
              .map((r) => (r.status === 'fulfilled' && r.data ? r.data : null))
              .filter((r) => r)
            setTeams(mappedResults)
          }
        }
        await getTeams()
      } else {
        setTeams([])
      }
      // Double check the presence of an offsite id
      if (offsiteId) {
        const getOffsiteParticipants = async () => {
          const response = await dispatch(
            smartwayApi.endpoints.fetchAllEntities.initiate({ entity: 'participants', offsiteId: offsiteId, params: { page_size: 100 } })
          )
          if (response && response.data) {
            setOffsiteParticipants(response.data.filter(p => p.confirmation !== false))
          }
        }
        await getOffsiteParticipants()
      }
      setIsLoading(false)
    }
    fetchData()
  }, [dispatch, activity.groups, activity.links, offsiteId])

  useEffect(() => {
    if (offsiteParticipants && !!offsiteParticipants.length) {
      if (teams && !!teams.length) {
        const selected = teams.reduce((acc, curr) => {
          if (curr.participants && !!curr.participants.length) {
            curr.participants.forEach((participantId) => {
              if (!acc.find((p) => p.id === participantId)) {
                const fullParticipant = offsiteParticipants.find((p) => p.id === participantId)
                acc.push({...fullParticipant, team: curr })
              }
            })
          }
          return acc
        }, [])
        setSelectedParticipants(selected)
      } else {
        setSelectedParticipants([])
      }
    } 
  }, [offsiteParticipants, teams])

  return offsiteId ? isLoading ? (
    <WorkshopSkeleton />
  ) : (
    <>
      <Grid container rowSpacing={6} columnSpacing={3} pb={8}>
        <Grid item xs={12} sm={6} md={3}>
          <AddCard title="Add a team" icon="people" onAdd={handleAddTeam} />
        </Grid>
        {teams.map((team, index) => (
          <Grid key={`card-${index}`} item xs={12} sm={6} md={3}>
            <TeamCard
              {...team}
              offsiteParticipants={offsiteParticipants}
              title={team.name}
              onEdit={handleEditTeam}
              onDelete={handleDeleteTeam}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container rowSpacing={6} columnSpacing={3} pb={8}>
        <Grid item xs={12} sm={6} md={3}>
          <AddCard title="Add a link" icon="link" onAdd={handleAddLink} />
        </Grid>
        {activity?.links && !!activity.links.length
          ? activity.links.map((link, index) => (
              <Grid key={`card-${index}`} item xs={12} sm={6} md={3}>
                <LinkCard {...link} onEdit={handleEditLink} onDelete={handleDeleteLink} />
              </Grid>
            ))
          : null}
      </Grid>
      <TeamDialog
        open={teamDialogOpen}
        participants={offsiteParticipants}
        selectedParticipants={selectedParticipants}
        title="Add a team"
        team={editingTeam}
        onSave={handleSaveTeam}
        onClose={() => setTeamDialogOpen(false)}
      />
      <LinkDialog
        open={linkDialogOpen}
        title="Add a link"
        link={editingLink}
        onSave={handleSaveLink}
        onClose={() => setLinkDialogOpen(false)}
      />
    </>
  ) : null
}

export default ActivityWorkshopCustomization
