import { format } from 'date-fns'

const getValueLabel = ({ field, options, userPreferences }) => {
  if (!field) return ''
  if (field.type === 'select') {
    const variables = !!field.options.length && field.name !== 'roommates'
      ? {
          optionsToCheck: field.options,
          value: 'value',
          label: 'label'
        }
      : {
          optionsToCheck: options[field.name],
          value: 'id',
          label: 'name'
        }
    if (field.multiple) {
      const _options = variables.optionsToCheck && Array.isArray(userPreferences[field.name]) ? variables.optionsToCheck.filter((o) =>
        userPreferences[field.name].includes(o[variables.value])
      ) : []
      return !!_options.length ? _options.map((o) => o[variables.label]).join(', ') : '-'
    }
    const _option = variables.optionsToCheck ? variables.optionsToCheck.find((o) => o.value === userPreferences[field.name]) : null
    return !!_option ? _option[variables.label] : '-'
  }
  if (field.type === 'text') {
    return userPreferences[field.name] || '-'
  }
  if (field.type === 'datepicker' && userPreferences[field.name]) {
    return format(new Date(userPreferences[field.name]), 'dd/MM/yyyy - HH:mm')
  }
}
const getCustomValueLabel = ({ field, userPreferences }) => {
  const response = userPreferences.customs && userPreferences.customs.length ? userPreferences.customs.find((c) => c.field_key === field.field_key) : null
  if (response) {
    if (response.value_type === 'STRING') {
      return response.value || '-'
    }
  }
  return '-'
}

export { getValueLabel, getCustomValueLabel }