import { smartwayApi } from 'services/api'
import { redirect, redirectDocument } from 'react-router-dom'

import { convertFiltersToQueryString } from 'utils/filters'
import { OFFSITE_STATUSES } from 'utils/offsiteStatus'
import { transformOffsitePayloadToPost } from 'utils/offsites'

export const editOrCreateAction =
  (dispatch, entity, redirectBaseUrl) =>
  async ({ request }) => {
    try {
      const payload = await request.json()
      const subentity = payload.subentity || 'default'
      const actions = {
        room: {
          POST: 'createRoom',
          PATCH: 'updateRoom',
          DELETE: 'deleteRoom'
        },
        bulkRoom: {
          POST: 'createBulkRoom'
        },
        default: {
          POST: 'createEntity',
          PATCH: 'updateEntity'
        }
      }
      const action = actions[subentity][request.method]

      if (payload.onlyImages) {
        return { success: true, entity }
      }

      const result = await dispatch(
        smartwayApi.endpoints[action].initiate({ entity, ...payload }, { forceRefetch: true })
      )

      if (entity === 'package' && payload.agendas && !!payload.agendas.length) {
        // We need to update all agendas associated with this package
        let agendasToUpdate = payload.agendas

        // An agenda could have already been associated with a package
        // In this case we clone it and while cloning it we associate it to the premade package
        if (payload.agendasToClone && payload.agendasToClone.length) {
          agendasToUpdate = agendasToUpdate.filter(agenda => !payload.agendasToClone.map((a) => a.id).includes(agenda))
          await Promise.all[payload.agendasToClone.map(agenda => {
            return dispatch(smartwayApi.endpoints.cloneEntity.initiate({ entity: 'agenda', ...agenda, title: `Copy of ${agenda.title}`, id: agenda.id, premade_package: result.data.id }))
          })]
        }

        // If there are any agenda left to update we do it here
        if (agendasToUpdate.length) {
          await Promise.all(agendasToUpdate.map(agenda => {
            return dispatch(smartwayApi.endpoints.updateEntity.initiate({ entity: 'agenda', id: agenda, premade_package: result.data.id }))
          }))
        }
      }

      if (action === 'createEntity') {
        if (result.data) {
          // TODO: understand why redirect doesn't work and does not trigger reload of the routes
          return redirectDocument(`${redirectBaseUrl}/${result.data.id}?new=true`)
        } else {
          let message = result.error?.message || 'Error during creation'
          if (result.error.name) {
            message = 'This name is already taken! Please contact your Smartway referent for more information.'
          }
          return {
            error: 'Error during creation',
            fullError: message
          }
        }
      }
      
      if (result && result.data) {
        return { success: true, entity, data: result.data }
      }
      return { error: 'There was an error!' }

    } catch (error) {
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }

export const listAction =
  (dispatch, entity, redirectBaseUrl) =>
  async ({ request }) => {
    const payload = await request.json()
    if (payload.intent === 'filters') {
      delete payload.intent
      return filterAction(payload, redirectBaseUrl)
    }
    if (payload.intent === 'delete') {
      delete payload.intent
      return deleteAction(payload, request, dispatch, entity, redirectBaseUrl)
    }
    // CLONE LOCATION / PACKAGE
    if (['cloneLocation', 'clonePackage', 'cloneAgenda'].includes(payload.intent)) {
      const titleParam = payload.title ? 'title' : 'name'
      const clonedLocation = await dispatch(
        smartwayApi.endpoints.cloneEntity.initiate({
          entity: payload.entity,
          id: payload.id,
          [titleParam]: `Copy of ${payload[titleParam]}`,
        })
      )
      if (clonedLocation.data) {
        const requestUrl = new URL(request.url)
        const searchParams = new URLSearchParams(requestUrl.search)
        if (searchParams.has('new')) {
          searchParams.delete('new')
        }
        searchParams.delete('index')
        searchParams.append('copied', true)
        const search = searchParams.toString()
        return redirect(`${redirectBaseUrl}${!!search ? `?${search}` : ''}`)
      }
    }

    // CLONE OFFER / OFFSITE
    if (payload.intent === 'cloneOffsite') {
      const originalOffsite = await dispatch(
        smartwayApi.endpoints.fetchEntity.initiate({
          entity: 'offsite',
          id: payload.id
        })
      )
      if (originalOffsite.data) {
        const newOffsitePayload = transformOffsitePayloadToPost(originalOffsite.data)
        if (newOffsitePayload.status) {
          const mappedOffsitesStatuses = OFFSITE_STATUSES.map(s => s.name)
          newOffsitePayload.status = newOffsitePayload.status.some(s => mappedOffsitesStatuses.includes(s)) ? ['DRAFT'] : newOffsitePayload.status
        }
        const clonedOffsite = await dispatch(
          smartwayApi.endpoints.createEntity.initiate({
            entity: 'offsite',
            ...newOffsitePayload,
            title: `Copy of ${newOffsitePayload.title}`
          })
        )

        if (clonedOffsite.data) {
          // Agenda is only an id
          const agendaId =
            originalOffsite.data.agendas && originalOffsite.data.agendas[0]
              ? originalOffsite.data.agendas[0]
              : null
          if (agendaId) {
            // Se c'è un agenda lo clono nel nuovo offsite
            await dispatch(
              smartwayApi.endpoints.cloneEntity.initiate({
                entity: 'agenda',
                id: agendaId,
                offsite: clonedOffsite.data.id,
                is_template: false
              })
            )
          } else {
            // Altrimenti lo creo da zero
            await dispatch(
              smartwayApi.endpoints.createEntity.initiate({
                entity: 'agenda',
                offsite: clonedOffsite.data.id,
                title: `Agenda for ${clonedOffsite.data.title}`,
                is_template: false,
              })
            )
          }

          return redirect(`${redirectBaseUrl}/${clonedOffsite.data.id}/definition?new=true`)
        }
      }
    }
    return { error: 'There was an error!' }
  }

export const filterAction = (payload, redirectBaseUrl) => {
  const { activeFilters, initialFilters } = payload
  const queryString = convertFiltersToQueryString(initialFilters, activeFilters)
  const searchParams = new URLSearchParams(queryString)
  return redirect(`${redirectBaseUrl}?${searchParams.toString()}`)
}
export const deleteAction = async (payload, request, dispatch, entity, redirectBaseUrl) => {
  const deleteResponse = await dispatch(
    smartwayApi.endpoints.deleteEntity.initiate({ entity, id: payload.id })
  )
  if (deleteResponse && deleteResponse.data && deleteResponse.data.success) {
    const requestUrl = new URL(request.url)
    const searchParams = new URLSearchParams(requestUrl.search)
    if (searchParams.has('new')) {
      searchParams.delete('new')
    }
    searchParams.delete('index')
    searchParams.append('deleted', true)
    const search = searchParams.toString()
    return redirect(`${redirectBaseUrl}${!!search ? `?${search}` : ''}`)
  } else {
    return {
      error: true
    }
  }
}
