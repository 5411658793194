import { useState, useEffect } from 'react'
import { useRouteLoaderData, useSubmit } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import Form from 'components/Form'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import LoadingButton from '@mui/lab/LoadingButton'

import OffsiteTransformationDialog from 'components/Dialogs/OffsiteTransformationDialog'
import AgendaPriceDialog from 'components/Dialogs/AgendaPriceDialog'

import { format } from 'date-fns'
import getSchema from '../schemas/offer'
import { generateAgendaPrice } from 'utils/agenda'
import { OfferSkeleton } from '../components/offer/Skeletons'


const OfferStep = ({ parent = 'offsite' }) => {
  const dispatch = useDispatch()
  const [confirmationLoading, setConfirmationLoading] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [agendaPriceDialogOpen, setAgendaPriceDialogOpen] = useState(false)
  const [agendaSuggestedPrice, setAgendaSuggestedPrice] = useState(null)
  const [offsiteAgenda, setOffsiteAgenda] = useState(null)
  const [suggestedPrice, setSuggestedPrice] = useState(null)
  const submit = useSubmit()
  const { offsite } = useRouteLoaderData(`${parent}-detail`)

  const handleOnSubmit = (values) => {
    const payload = {
      ...values,
      company: offsite.company,
      valid_by: values.valid_by ? format(new Date(values.valid_by), 'yyyy-MM-dd') : null,
      last_offer_sent: values.last_offer_sent
        ? format(new Date(values.last_offer_sent), 'yyyy-MM-dd')
        : null
    }
    submit({ id: offsite.id, ...payload }, { method: 'patch', encType: 'application/json' })
  }

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false)
    setConfirmationLoading(false)
  }

  const handleContractSigned = () => {
    setConfirmationLoading(true)
    setConfirmationDialogOpen(true)
  }

  const handleContractSignedConfirmation = async (additionalValues) => {
    setConfirmationDialogOpen(false)
    submit(
      { id: offsite.id, transformInOffsite: true, ...additionalValues },
      { method: 'patch', encType: 'application/json' }
    )
  }

  const handleSuggestedPriceClick = () => {
    setAgendaPriceDialogOpen(true)
  }

  const handleSaveSuggestedPrice = (price) => {
    setAgendaPriceDialogOpen(false)
    setSuggestedPrice(price)
    handleOnSubmit({ price_per_pax: price })
  }

  useEffect(() => {
    const fetchAgenda = async () => {
      const agenda = await dispatch(
        smartwayApi.endpoints.fetchEntity.initiate({
          entity: 'agenda',
          id: offsite.agendas[0]
        })
      )
      if (agenda && agenda.data) {
        setOffsiteAgenda(agenda.data)
        const suggestedPrices = generateAgendaPrice({ agenda: agenda.data, offsite })
        const { total } = suggestedPrices
        const suggestedPrice = <MDBox display="inline-flex" alignItems="center">
          Suggested price: {total.toLocaleString('en-EN', { style: 'currency', currency: 'EUR' })} <IconButton size="small" onClick={handleSuggestedPriceClick}><Icon>info</Icon></IconButton>
        </MDBox>
        setAgendaSuggestedPrice(suggestedPrice)
      }
    }
    if (offsite && offsite.agendas && !!offsite.agendas.length && !offsiteAgenda) {
      fetchAgenda()
    }
  }, [dispatch, offsite, offsiteAgenda])

  return (
    <MDBox>
      <OffsiteTransformationDialog 
        offer={offsite}
        open={confirmationDialogOpen}
        onSave={handleContractSignedConfirmation}
        onClose={closeConfirmationDialog}
      />
      <MDBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <MDBox
          mt={2}
          mb={1}
          width="100%"
          maxWidth="500px"
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <MDTypography variant="h3" fontWeight="medium">
            Commercial & Economics
          </MDTypography>
          {offsite.status.includes('SIGNED_CONTRACT') ? (
            <MDTypography variant="subtitle" fontWeight="medium" color="success">
              Contract Signed 🥳
            </MDTypography>
          ) : (
            <LoadingButton
              variant="gradient"
              color="primary"
              size="small"
              loading={confirmationLoading}
              sx={{
                color: 'white !important',
                backgroundColor: 'primary.main',
                '&:hover': { backgroundColor: 'primary.light' }
              }}
              onClick={handleContractSigned}>
              Contract signed?
            </LoadingButton>
          )}
        </MDBox>
        <MDBox
          width="100%"
          maxWidth="500px"
          mb={2}
        >
          {offsite.status.includes('SIGNED_CONTRACT') ? (
            <MDTypography variant="subtitle2">
              You cannot modify details of the offer once the client has signed the contract!
            </MDTypography>
          ) : null}
        </MDBox>
        <MDBox
          position="relative"
          width="100%"
          maxWidth="500px"
          sx={
            confirmationLoading
              ? {
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    top: '-5px',
                    left: '-5px',
                    width: 'calc(100% + 10px)',
                    height: 'calc(100% + 10px)',
                    backdropFilter: 'blur(4px)',
                    pointerEvents: 'none',
                    zIndex: 1
                  }
                }
              : {}
          }>
          {agendaSuggestedPrice ? (
            <>
              <Form
                {...getSchema({ offsite, agendaSuggestedPrice })}
                values={{
                  ...offsite,
                  price_per_pax: suggestedPrice || offsite.price_per_pax,
                  valid_by: offsite.valid_by ? new Date(offsite.valid_by) : null,
                  last_offer_sent: offsite.last_offer_sent ? new Date(offsite.last_offer_sent) : null
                }}
                onSubmit={handleOnSubmit}
              />
               <AgendaPriceDialog
                open={agendaPriceDialogOpen} 
                agenda={offsiteAgenda}
                offsite={offsite}
                title="Suggested price for your agenda"
                onSave={handleSaveSuggestedPrice}
                onClose={() => setAgendaPriceDialogOpen(false)} 
              />
            </>
          ) : (
            <OfferSkeleton />
          ) }
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export default OfferStep
