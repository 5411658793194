import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { smartwayApi } from 'services/api'
import { notify } from 'store/reducers/UISlice'
import { ACTIVITY_NOTIFICATIONS } from 'utils/events'

import { getUTCValue } from 'utils/dates'
import { format, parseISO } from 'date-fns'
import { formatInTimeZone, utcToZonedTime, fromZonedTime } from 'date-fns-tz'

import CalendarSlot from 'utils/events'

const useActivities = ({ agendaId }) => {
  const profile = useSelector((state) => state.auth.profile)
  const dispatch = useDispatch()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isTemplate, setIsTemplate] = useState(true)
  const [slots, setSlots] = useState([])
  const [filteredSlots, setFilteredSlots] = useState([])
  const [initialDate, setInitialDate] = useState(new Date('1970-01-01:00:00:00'))
  const [customActivities, setCustomActivities] = useState([])
  const [agendaInfos, setAgendaInfos] = useState({})

  const notifyFeedback = ({ success }) => {
    if (success) {
      dispatch(
        notify({
          title: 'Well done!',
          message: 'Agenda was updated successfully',
          type: 'success',
          icon: 'done'
        })
      )
    } else {
      dispatch(
        notify({
          title: 'Ops!',
          message: 'There was an error updating the agenda',
          type: 'error',
          icon: 'error'
        })
      )
    }
  }

  const createAgenda = async ({ params }) => {
    try {
      const newAgenda = await dispatch(
        smartwayApi.endpoints.createEntity.initiate({
          entity: 'agenda',
          ...params
        })
      )
      if (newAgenda && newAgenda.data) {
        return newAgenda.data
      }
    } catch (error) {
      console.error(error)
      return error
    }
  }
  const fetchAgenda = useCallback(
    async (agendaId) => {
      try {
        const response = await dispatch(
          smartwayApi.endpoints.fetchEntity.initiate({
            entity: 'agenda',
            id: agendaId
          })
        )
        return response
      } catch (error) {
        console.error(error)
      }
    },
    [dispatch]
  )

  const fetchOffsite = useCallback(
    async (id) => {
      try {
        const response = await dispatch(
          smartwayApi.endpoints.fetchEntity.initiate({
            entity: 'offsite',
            id
          })
        )
        return response
      } catch (error) {
        console.error(error)
      }
    },
    [dispatch]
  )

  const refreshActivities = async () => {
    setIsLoading(true)
    const agenda = await fetchAgenda(agendaId)
    if (agenda && agenda.data) {
      const { activities } = agenda.data
      const newSlots = activities.map((a) => new CalendarSlot(a))
      newSlots.map((slot) => slot.checkIsOverlapSlot(activities))
      setSlots(newSlots)
      setFilteredSlots(newSlots)
      setIsLoading(false)
    }
  }

  const filterActivities = (filters) => {
    if (filters.types) {
      const _slots = slots.filter(({ slot }) => {
        return filters.types.includes(slot.activityType.type)
      })
      setFilteredSlots(_slots)
    }
  }

  const saveActivity = async (activity) => {
    const action = activity.id ? 'updateEntity' : 'createEntity'
    const { custom_image, ...restActivity } = activity
    try {
      
      const payload = {
        ...restActivity,
        agenda: agendaId
      }
      const [activityResponse, imageResponse] = await Promise.all([
        dispatch(
          smartwayApi.endpoints[action].initiate({
            entity: 'activities',
            ...payload
          })
        ),
        ...( activity.id && custom_image && custom_image instanceof File ? [
          saveActivityCustomImage(activity, custom_image)
        ] : [] ),
      ])
      if (activityResponse && activityResponse.data) {
        if (!activity.id && custom_image && custom_image instanceof File) {
          await saveActivityCustomImage(activityResponse.data, custom_image)
        }
        const updatedAgenda = await fetchAgenda(agendaId)
        let returnedData = activityResponse.data
        if (updatedAgenda && updatedAgenda.data) {
          const { activities } = updatedAgenda.data
          const newSlots = activities.map((a) => new CalendarSlot(a))
          newSlots.map((slot) => slot.checkIsOverlapSlot(activities))
          returnedData = newSlots.find(({ slot }) => slot.id === activityResponse.data.id)
          setSlots(newSlots)
          setFilteredSlots(newSlots)
        }
        notifyFeedback({ success: true })
        return returnedData
      } else {
        console.warn(activityResponse)
        throw new Error('Error saving activity')
      }
    } catch (error) {
      console.error(error)
      notifyFeedback({ success: false })
      return error
    }
  }

  const saveCustomActivity = async (activity) => {
    const companyAgendaId =
      profile && profile.company && profile.company.agendas ? profile.company.agendas[0] : null
    const action = activity.id ? 'updateEntity' : 'createEntity'
    try {
      const { id, location, links, groups, participants, __address, my_group, custom_image, custom_image_formats, created_at, updated_at, custom_id, ...activityToSave } = activity
      const payload = {
        ...activityToSave,
        agenda: companyAgendaId
      }
      
      const [activityResponse, imageResponse] = await Promise.all([
        dispatch(
          smartwayApi.endpoints[action].initiate({
            entity: 'activities',
            id: activity.id,
            ...payload
          })),
        ...( activity.id && custom_image && custom_image instanceof File ? [
          saveActivityCustomImage(activity, custom_image)
        ] : [] ),
      ])

      if (activityResponse && activityResponse.data) {
        if (!activity.id && custom_image && custom_image instanceof File) {
          await saveActivityCustomImage(activityResponse.data, custom_image)
        }
        const updatedAgenda = await fetchAgenda(companyAgendaId)
        if (updatedAgenda && updatedAgenda.data) {
          setCustomActivities(updatedAgenda.data.activities)
        }
        notifyFeedback({ success: true })
        return activityResponse.data
      }
    } catch (error) {
      console.error(error)
      notifyFeedback({ success: false })
      return error
    }
  }

  const saveActivityCustomImage = async (activity, image) => {
    const formData = new FormData()
    const endpoint = activity.custom_image_formats ? 'updateEntity' : 'createEntity'
    formData.append('custom_image', image)
    try {
      const response = await dispatch(
        smartwayApi.endpoints[endpoint].initiate({
          entity: 'activities',
          id: activity.id,
          action: endpoint === 'createEntity' ? `${activity.id}/image/` : 'image/',
          bodyType: 'formData',
          formData
        })
      )
      if (response && response.data) {
        return response.data
      }
    } catch (error) {
      console.error(error)
      return error
    }
  }

  const cloneCustomActivity = async (formattedEvent) => {
    try {
      const {
        start_at,
        end_at,
        custom_id,
        custom_address,
        custom_longitude,
        custom_latitude,
        title,
        description
      } = formattedEvent
      const custom_notification_title = ACTIVITY_NOTIFICATIONS.CUSTOM.title
      const custom_notification_body = ACTIVITY_NOTIFICATIONS.CUSTOM.body.replace('{title}', title ? title : '')
      const response = await dispatch(
        smartwayApi.endpoints.cloneEntity.initiate({
          id: formattedEvent.id,
          agenda: agendaId,
          entity: 'activities',
          start_at,
          end_at,
          custom_id,
          custom_address,
          custom_longitude,
          custom_latitude,
          title,
          description,
          custom_notification_title,
          custom_notification_body
        })
      )
      if (response && response.data) {
        const updatedAgenda = await fetchAgenda(agendaId)
        if (updatedAgenda && updatedAgenda.data) {
          const { activities } = updatedAgenda.data
          const newSlots = activities.map((a) => new CalendarSlot(a))
          newSlots.map((slot) => slot.checkIsOverlapSlot(activities))
          setSlots(newSlots)
          setFilteredSlots(newSlots)
          notifyFeedback({ success: true })
          return updatedAgenda
        }
      }
    } catch (error) {
      console.error(error)
      notifyFeedback({ success: false })
      return error
    }
  }

  const deleteActivity = async (activityId) => {
    try {
      const response = await dispatch(
        smartwayApi.endpoints.deleteEntity.initiate({
          entity: 'activities',
          id: activityId
        })
      )
      if (response && response.data) {
        const updatedAgenda = await fetchAgenda(agendaId)
        if (updatedAgenda && updatedAgenda.data) {
          const { activities } = updatedAgenda.data
          const newSlots = activities.map((a) => new CalendarSlot(a))
          newSlots.map((slot) => slot.checkIsOverlapSlot(activities))
          setSlots(newSlots)
          setFilteredSlots(newSlots)
        }
        notifyFeedback({ success: true })
        return response.data
      }
    } catch (error) {
      console.error(error)
      return error
    }
  }
  const deleteCustomActivity = async (activityId) => {
    const companyAgendaId =
      profile && profile.company && profile.company.agendas ? profile.company.agendas[0] : null
    try {
      const response = await dispatch(
        smartwayApi.endpoints.deleteEntity.initiate({
          entity: 'activities',
          id: activityId
        })
      )
      if (response && response.data) {
        const updatedAgenda = await fetchAgenda(companyAgendaId)
        if (updatedAgenda && updatedAgenda.data) {
          setCustomActivities(updatedAgenda.data.activities)
        }
        notifyFeedback({ success: true })
        return response.data
      }
    } catch (error) {
      console.error(error)
      return error
    }
  }

  const getInitialDate = (act) => {
    const firstActivity = act.sort(
      (a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
    )
    return new Date(firstActivity[0].start_at).getTime()
  }

  // Function to convert UTC to local time
  const convertUTCToLocal = (utcDateTime, userTimezone) => {
    const userTimezoneOffset = new Date().getTimezoneOffset() * 60 * 1000 // in milliseconds
    const utcDate = new Date(new Date(utcDateTime).getTime() + userTimezoneOffset)
    console.debug('Server date - UTC', utcDateTime)
    return utcDateTime
  }

  // Function to display events in local time
  const displayEventsInLocalTime = (event, userTimezone = 'Europe/Rome') => {
    return {
      ...event,
      originalStartAt: event.start_at,
      originalEndAt: event.end_at,
      start_at: convertUTCToLocal(event.start_at, userTimezone),
      end_at: convertUTCToLocal(event.end_at, userTimezone)
    }
  }

  useEffect(() => {
    const initialFetch = async () => {
      try {
        const companyAgendaId =
          profile && profile.company && profile.company.agendas ? profile.company.agendas[0] : null
        const [agenda, customAgenda] = await Promise.all([
          fetchAgenda(agendaId),
          ...(companyAgendaId
            ? [fetchAgenda(companyAgendaId)]
            : [new Promise((resolve) => resolve({ data: null }))])
        ])
        if (agenda && agenda.data) {
          const { activities, ...rest } = agenda.data
          const newSlots = activities.map((a) => new CalendarSlot(a))
          newSlots.map((slot) => slot.checkIsOverlapSlot(activities))
          setSlots(newSlots)
          setFilteredSlots(newSlots)
          setAgendaInfos(rest)
          if (!agenda.data.is_template) {
            if (activities && activities.length) {
              setInitialDate(getInitialDate([...agenda.data.activities]))
            } else {
              if (agenda.data.offsite) {
                const offsite = await fetchOffsite(agenda.data.offsite)
                setInitialDate(offsite.data.start_at)
              }
            }
            setIsTemplate(false)
          } else {
            setInitialDate(getUTCValue(new Date('1970-01-01:00:00:00')))
            setIsTemplate(true)
          }
        }
        if (customAgenda && customAgenda.data) {
          setCustomActivities(customAgenda.data.activities)
        }
      } catch (error) {
        setIsError(true)
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
    if (!slots.length && agendaId) {
      initialFetch()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    createAgenda,
    saveActivity,
    refreshActivities,
    filterActivities,
    saveCustomActivity,
    cloneCustomActivity,
    deleteActivity,
    deleteCustomActivity,
    saveActivityCustomImage,
    isTemplate,
    slots: filteredSlots,
    initialDate,
    customActivities,
    agendaInfos,
    isLoading,
    isError
  }
}

export { useActivities }
