import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper'
import Icon from '@mui/material/Icon'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const BulkActionsToolbar = ({ selected, onSelectAll, onChangeStatus, onDelete, onSendInvites }) => {

  
  return (
    <MDBox position="fixed" bottom={20} zIndex={'modal'} left={{ xs: '4px', md: 'unset' }} right={{ xs: '4px', md: 0 }} width={{ xs: 'calc(100vw - 8px)', xl: 'calc(100% - 265px)'}}>
      <MDBox display="flex" justifyContent="center">
        <Paper elevation={7} sx={{ zIndex: 'modal',  minWidth: { xs: '85%', lg: '700px', xl: '800px' } }}>
          <MDBox p={2} display="flex" justifyContent="space-between" alignItems="center" gap={{ xs: 1, md: 4 }} flexWrap="wrap">
            <MDTypography variant="body2" sx={{ minWidth: '76px'}}>
              {selected ? `${selected} selected` : '0 selected'}
            </MDTypography>
            <MDButton variant="outlined" color="secondary" onClick={onSelectAll}>
              Select everyone
            </MDButton>
            <MDBox sx={{ display: { xs: 'none', md: 'block' }}}>|</MDBox>
            <MDBox display="flex" gap={1} flexWrap="wrap">
              <MDButton color="secondary" disabled={!selected} onClick={onChangeStatus}>
                <Icon sx={{ mr: 1 }}>settings</Icon>
                Change status
              </MDButton>
              {/* <MDButton color="error" disabled={!selected} onClick={onDelete}>
                <Icon sx={{ mr: 1 }}>delete</Icon>
                Delete
              </MDButton> */}
              <MDButton color="primary" disabled={!selected} onClick={onSendInvites}>
                <Icon sx={{ mr: 1 }}>mail</Icon>
                Send invites
              </MDButton>
            </MDBox>
          </MDBox>
        </Paper>
      </MDBox>
    </MDBox>
  )
}

export default BulkActionsToolbar
