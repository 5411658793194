const schema = ({ editingMode, suggestedPrice, handleGenerateClick }) => {
  return {
    id: 'agenda-venue',
    fieldsets: [
      {
        id: 'general',
        sx: { display: 'block' },
        fields: [
          {
            type: 'text',
            name: 'title',
            label: 'Title',
            placeholder: 'Agenda title',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, sx: { '> div': { mb: 2 } } }
          },
          {
            type: 'number',
            name: 'nb_days',
            label: 'Number of days',
            placeholder: '2',
            validationType: 'number',
            validations: null,
            grid: { xs: 12 }
          },
          {
            type: 'number',
            name: 'price_per_person',
            label: 'Price per person',
            placeholder: '200',
            validationType: 'number',
            validations: null,
            helperText: suggestedPrice,
            grid: { xs: 12 }
          },
          {
            type: 'select',
            name: 'category',
            label: 'Category',
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            required: true,
            labelProp: 'name',
            valueProp: 'id',
            optionsGetter: { 
              endpoints: [
                {
                  entity: 'agendaCategories',
                }
              ]
            },
            grid: { xs: 12 },
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            placeholder: 'Agenda description',
            validationType: 'string',
            validations: null,
            size: 'small',
            insideCta: {
              label: 'Generate with AI',
              icon: 'auto_awesome',
              variant: 'outlined',
              color: 'primary',
              size: 'small',
              onClick: handleGenerateClick
            },
            grid: { xs: 12, sx: { '> div': { mb: 3 } } }
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      fullWidth: false,
      color: 'primary'
    }
  }
}

export default schema
