const venueAccommodation = ({ venueAccommodation, groups, dispatch, onPublishChange }) => {
  const pricesVisible = !!venueAccommodation?.price_switch

  const hosts = groups && groups.length ? groups.find((g) => g.name === 'Host') : {}
  const publishButton = venueAccommodation?.id
    ? venueAccommodation.published_at
      ? {
          label: 'Unpublish',
          color: 'warning',
          tooltip: 'By unpublishing, you will not be able to see the public page anymore',
          icon: 'visibility_off',
          onClick: () => onPublishChange(false, venueAccommodation)
        }
      : {
          label: 'Publish',
          color: 'success',
          tooltip: 'Be sure to have saved before publishing',
          icon: 'rocket_launch',
          onClick: () => onPublishChange(true, venueAccommodation)
        }
    : null

  return {
    id: 'venue-accommodation-form',
    title: 'Venue accommodation Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: 'General',
        type: 'accordion',
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'select',
            name: 'venue',
            label: 'Venue',
            labelProp: 'name',
            valueProp: 'id',
            optionsGetter: {
              endpoints: [
                {
                  entity: 'allInVenue'
                }
              ]
            },
            grid: { xs: 12, md: 6 },
            validationType: 'number',
            validations: [
              {
                type: 'nullable',
                params: []
              }
            ]
          },
          {
            type: 'text',
            label: 'Slug',
            name: 'slug',
            slugify: true,
            required: !!venueAccommodation.id,
            hidden: !venueAccommodation.id,
            validationType: 'string',
            validations: venueAccommodation.id
              ? [
                  {
                    type: 'slug',
                    params: [
                      'all-in-venues-accommodations',
                      'This slug is not available',
                      venueAccommodation.slug,
                      dispatch
                    ]
                  },
                  {
                    type: 'required',
                    params: ['This field is required']
                  }
                ]
              : null
          },
          {
            type: 'select',
            name: 'host',
            label: 'Host',
            labelProp: 'name',
            valueProp: 'profileId',
            required: true,
            optionsGetter: {
              endpoints: [
                {
                  name: 'host',
                  entity: 'profilesByRole',
                  params: { roles: hosts.id, transformResponse: true }
                }
              ]
            },
            grid: { xs: 12, md: 6 },
            validationType: 'number',
            validations: [
              {
                type: 'nullable',
                params: []
              },
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'address',
            name: 'address',
            label: 'Address',
            latitudeProp: 'address_latitude',
            longitudeProp: 'address_longitude',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: 'Address is required'
              }
            ]
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: null
          },
          {
            type: 'switch',
            name: 'price_switch',
            label: 'Is the price different from the venue?',
            validationType: 'boolean',
            validations: null,
            grid: { xs: 12, md: 4 }
          },
          {
            type: 'number',
            label: 'Min. price',
            name: 'price_min',
            validationType: 'number',
            suffix: '€',
            hidden: !pricesVisible,
            validations: null,
            grid: { xs: 12, md: 2.66 }
          },
          {
            type: 'number',
            label: 'Max. price',
            name: 'price_max',
            validationType: 'number',
            suffix: '€',
            hidden: !pricesVisible,
            validations: null,
            grid: { xs: 12, md: 2.66 }
          },
          {
            type: 'number',
            label: 'Normal price',
            name: 'normal_price',
            validationType: 'number',
            suffix: '€',
            hidden: !pricesVisible,
            validations: null,
            grid: { xs: 12, md: 2.66 }
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            required: true,
            placeholder: 'Write a description',
            validationType: 'string',
            maxLength: 2000,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'max',
                params: [2000, 'Description is too long. Keep it under 2000 characters']
              }
            ]
          },
          {
            type: 'video',
            label: 'Video',
            name: 'video',
            placeholder: 'Upload a video',
            validationType: 'string',
            collectionName: 'accommodations',
            innerField: {
              validationType: 'mixed',
              validations: [
                {
                  type: 'fileSize',
                  params: ['File too large', '104860000']
                },
                {
                  type: 'fileFormat',
                  params: ['Unsupported Format', ['video/mp4']]
                },
                {
                  type: 'nullable'
                }
              ],
              maxSize: 104860000,
              accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
            }
          }
        ]
      },
      {
        id: 'settings',
        title: 'Accommodation settings',
        type: 'accordion',
        fields: [
          {
            type: 'number',
            label: 'Download speed',
            name: 'download_speed',
            suffix: 'Mbps',
            validationType: 'number',
            validations: null,
            grid: { xs: 12, md: 6 }
          },
          {
            type: 'number',
            label: 'Upload speed',
            name: 'upload_speed',
            validationType: 'number',
            suffix: 'Mbps',
            validations: null,
            grid: { xs: 12, md: 6 }
          },
          {
            type: 'text',
            label: 'Park name',
            name: 'park_name',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 6 }
          },
          {
            type: 'address',
            name: 'park_address',
            label: 'Park position',
            latitudeProp: 'park_latitude',
            longitudeProp: 'park_longitude',
            validationType: 'string'
          }
        ]
      },
      {
        id: 'features',
        title: 'Features',
        type: 'accordion',
        fields: [
          {
            type: 'checkboxgroup',
            name: 'features',
            label: '',
            // groups: accommodationsFeatures,
            optionsGetter: {
              endpoints: [
                {
                  name: 'feature',
                  entity: 'locationFeatures',
                  id: 'all_in_venues',
                  formatGroup: (options) => {
                    return options.reduce((acc, curr) => {
                      const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                      if (alreadyInAcc) {
                        alreadyInAcc.controls.push({
                          name: curr.id,
                          label: curr.name
                        })
                      } else {
                        acc.push({
                          label: curr.subtype,
                          controls: [{ name: curr.id, label: curr.name }]
                        })
                      }
                      return acc
                    }, [])
                  }
                }
              ]
            }
          }
        ]
      },
      {
        id: 'premium_features',
        title: 'Premium features',
        type: 'accordion',
        fields: [
          {
            type: 'checkboxgroup',
            name: 'premium_features',
            label: '',
            // groups: accommodationsFeatures,
            optionsGetter: {
              endpoints: [
                {
                  entity: 'locationPremiumFeatures',
                  formatGroup: (options) => {
                    return options.reduce((acc, curr) => {
                      const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                      if (alreadyInAcc) {
                        alreadyInAcc.controls.push({
                          name: curr.id,
                          label: curr.name
                        })
                      } else {
                        acc.push({
                          label: curr.subtype,
                          controls: [{ name: curr.id, label: curr.name }]
                        })
                      }
                      return acc
                    }, [])
                  }
                }
              ]
            }
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default venueAccommodation
