const getSchema = ({ suggestedPrices }) => {
  // const { needDefaultPrices } = suggestedPrices
  let showExperientialFields = true
  let showCoworkingFields = true
  let showCateringFields = true
  // if (needDefaultPrices && needDefaultPrices.EXPERIENTIAL_ACTIVITY) {
  //   showExperientialFields = true
  // }
  // if (needDefaultPrices && needDefaultPrices.BUSINESS_ACTIVITY) {
  //   showCoworkingFields = true
  // }
  // if (needDefaultPrices && needDefaultPrices.FOOD_DRINK) {
  //   showCateringFields = true
  // }

  return {
    id: 'agenda-price-form',
    name: 'agenda-price-form',
    watchedFields: ['price_per_night', 'breakfast_price', 'lunch_price', 'dinner_price', 'experience_price', 'transfer_price', 'coworking_price', 'take_rate'],
    fieldsets: [
      {
        id: 'agenda-price',
        name: 'default-prices',
        title: 'Default prices',
        subtitle: 'These prices are used if no price is set for a specific activity. Otherwise the normal price will be used and default will be ignored (fields will be disabled)',
        fields: [
          {
            type: 'number',
            name: 'price_per_night',
            label: 'Price per night',
            suffix: '€',
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6, mr: 24 }
          },
          {
            type: 'number',
            name: 'breakfast_price',
            label: 'Breakfast default price',
            suffix: '€',
            disabled: !showCateringFields,
            grid: { xs: 4 }
          },
          {
            type: 'number',
            name: 'lunch_price',
            suffix: '€',
            label: 'Lunch default price',
            disabled: !showCateringFields,
            grid: { xs: 4 }
          },
          {
            type: 'number',
            name: 'dinner_price',
            suffix: '€',
            label: 'Dinner default price',
            disabled: !showCateringFields,
            grid: { xs: 4 }
          },
          {
            type: 'number',
            suffix: '€',
            name: 'experience_price',
            label: 'Experience default price',
            disabled: !showExperientialFields,            
            grid: { xs: 4 }
          },
          {
            type: 'number',
            name: 'coworking_price',
            suffix: '€',
            label: 'Coworking default price',
            disabled: !showCoworkingFields,
            grid: { xs: 4 }
          },
          {
            type: 'number',
            name: 'transfer_price',
            suffix: '€',
            label: 'Single transfer medium price',
            grid: { xs: 4 }
          }
        ]
      },
      {
        id: 'take-rate',
        name: 'take-rate',
        title: 'Take rate',
        sx: {
          marginTop: 0,
          paddingTop: 0
        },
        fields: [
          {
            type: 'number',
            name: 'take_rate',
            suffix: '%',
            label: 'Take rate',
            validationType: 'number',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 6 }
          }
        ]
      }
    ]
  }
}

export default getSchema