import { useState, useEffect, useCallback } from 'react'

import { smartwayApi } from 'services/api'
import { useDispatch } from 'react-redux'

import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from 'components/Fields/Checkbox'

import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'

const CheckboxGroup = ({
  name,
  label,
  groups,
  value,
  errors,
  sx = {},
  optionsGetter,
  onChange
}) => {
  const dispatch = useDispatch()
  const [checkboxGroups, setCheckboxGroups] = useState(groups || [])
  const handleChange = (e) => {
    const { checked, name: targetName } = e.target
    const coercedValue = Number.isNaN(Number(targetName)) ? targetName : Number(targetName)
    onChange({
      target: {
        name,
        value: checked
          ? [...value, coercedValue]
          : value.filter((v) => v !== coercedValue)
      }
    })
  }

  const getAsyncOptions = useCallback(async () => {
    let allResults = []
    for (let i = 0; i < optionsGetter.endpoints.length; i++) {
      const endpoint = optionsGetter.endpoints[i]
      const params = endpoint.params || {}
      const idParam = endpoint.id || null
      const response = await dispatch(
        smartwayApi.endpoints.fetchAllEntities.initiate({
          entity: endpoint.entity,
          id: idParam,
          ...params
        })
      )
      const entityResults =
        response.status === 'fulfilled' && response.data ? response.data : []
      const newOptions = endpoint.formatGroup
        ? endpoint.formatGroup(entityResults)
        : entityResults
      allResults = [...allResults, ...newOptions]
    }
    setCheckboxGroups(allResults)
  }, [dispatch, optionsGetter])

  useEffect(() => {
    if (!groups && !!optionsGetter) {
      getAsyncOptions()
    }
  }, [getAsyncOptions, groups, optionsGetter])

  return checkboxGroups ? (
    <MDBox sx={{ mb: 4, mt: 1, ...sx }}>
      { label ? (
        <MDTypography variant="body1">{label}</MDTypography>
      ) : null }
      <MDBox sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {checkboxGroups && checkboxGroups.length
          ? checkboxGroups.map(({ label, controls }, groupIndex) => {
              return (
                <FormControl
                  key={`group-${groupIndex}`}
                  sx={{ minWidth: { xs: '100%', md: '25%'} }}
                  component="fieldset"
                  variant="standard">
                    {label ? (
                      <MDTypography variant="button">{label}</MDTypography>
                    ) : null}
                  <FormGroup>
                    {controls && controls.length
                      ? controls.map((checkbox) => {
                          return (
                            <Checkbox 
                              key={checkbox.name}
                              {...checkbox}
                              name={checkbox.name.toString()}
                              value={
                                value ? value.includes(checkbox.name) : null
                              }
                              onChange={handleChange}
                            />
                          )
                        })
                      : null}
                  </FormGroup>
                </FormControl>
              )
            })
          : null}
      </MDBox>
      {errors ? (
        <MDBox>
          <MDTypography color="error" variant="caption">
            {errors}
          </MDTypography>
        </MDBox>
      ) : null}
    </MDBox>
  ) : null
}

export default CheckboxGroup
