import { useState, useEffect } from 'react'
import { format } from 'date-fns'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Table from 'components/Tables/Table'

import { OffsitesTableSkeleton } from './Skeletons'

import { resolveFullOffsites } from 'utils/offsites'

const OffsitesManagerTable = ({ offsites, dispatch, profile }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [offsitesTable, setOffsitesTable] = useState(null)

  useEffect(() => {
    const resolveOffsites = async () => {
      try {
        const results = await resolveFullOffsites({ offsites, dispatch, needOffsiteData: true })
        if (!results) {
          throw new Error('Failed to resolve offsites')
        }
        setOffsitesTable({
          columns: [
            {
              header: 'title',
              accessorKey: 'title'
            },
            ...(profile?.is_staff
              ? [
                  {
                    header: 'company',
                    accessorKey: 'company.name'
                  }
                ]
              : []),
            {
              header: 'venue',
              accessorFn: (row) => row.venue?.name
            },
            {
              header: 'dates',
              accessorFn: (row) => {
                const { indicative_number_days, start_at, end_at } = row
                const offsiteFormattedDates = indicative_number_days
                  ? `${indicative_number_days} day${indicative_number_days > 1 ? 's' : ''}`
                  : `${format(new Date(start_at), 'dd MMM')} - ${format(
                      new Date(end_at),
                      'dd MMM'
                    )}`
                return offsiteFormattedDates
              }
            },
            ...(profile?.is_staff
              ? [
                  {
                    header: 'role',
                    accessorKey: 'your_role'
                  }
                ]
              : [])
          ],
          rows: results
        })
      } catch (error) {
        console.warn(error)
      } finally {
        setIsLoading(false)
      }
    }
    if (!offsitesTable) {
      resolveOffsites()
    }
    return () => {}
  }, [offsites])

  if (isLoading) {
    return <OffsitesTableSkeleton numberOfRows={offsites.length} />
  }

  return offsitesTable && offsitesTable.rows ? (
    <>
      <MDTypography variant="h5" fontWeight="medium" display="flex" alignItems="center">
        <Icon sx={{ mr: 1 }}>handyman</Icon>
        Event{!offsites.length ? null : 's'} in preparation
      </MDTypography>
      <MDBox mt={1} mb={2}>
        <Card>
          <MDBox px={2}>
            <Table
              table={offsitesTable}
              filtersOptions={{
                search: false,
                type: null
              }}
              options={{
                enableFilters: false,
                enableGlobalFilter: false,
                enableRowSelection: false,
                positionActionsColumn: 'last',
                initialState: {
                  showGlobalFilter: false,
                  columnPinning: {
                    right: ['mrt-row-actions']
                  }
                }
              }}
              rowActions={[
                {
                  id: 'edit',
                  title: 'Edit',
                  icon: 'edit',
                  color: 'secondary',
                  getLink: (row) => {
                    return {
                      href: `/dashboard/offsites/${row.id}/definition`,
                      target: '_blank'
                    }
                  }
                },
                {
                  id: 'see',
                  title: 'See public landing',
                  icon: 'visibility',
                  color: 'secondary',
                  getLink: (row) => {
                    return {
                      href: `https://listings.smartway.work/offsites/${row.id}`,
                      target: '_blank'
                    }
                  }
                }
              ]}
              sx={{
                mt: 0
              }}
            />
          </MDBox>
        </Card>
      </MDBox>
    </>
  ) : null
}

export default OffsitesManagerTable
