import { Suspense, useState, useEffect, useMemo } from 'react'

import {
  Await,
  Link,
  useLoaderData,
  useSubmit,
  useSearchParams
} from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'
import { capitalizeFirstLetter } from 'utils/functions'
import { getMainImage } from 'utils/image'
import {
  convertQueryStringToFilters,
  getActiveFiltersNumber
} from 'utils/filters'
import { debounce } from '@mui/material/utils'

import DashboardNavbar from 'components/DashboardNavbar'
import LocationCard from 'components/Cards/LocationCard'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import Pagination from 'components/Pagination'
import FiltersDialog from 'components/Dialogs/FiltersDialog'
import { FilterButton } from 'components/Filters/Filters'

import { FiltersButtonSkeleton, CardsSkeleton } from './Skeletons'

import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'

import noResults from 'assets/illustrations/noresults.svg'
import Form from 'components/Form/Form'

const LocationListPage = ({ entityName, routeSegment }) => {
  const { results, initialFilters } = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const submit = useSubmit()
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)
  const [cloneConfirmationOpen, setCloneConfirmationOpen] = useState(false)
  const [markedForDel, setMarkedForDel] = useState(null)
  const [markedForClone, setMarkedForClone] = useState(null)
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false)
  const [activeFiltersNumber, setActiveFiltersNumber] = useState(0)
  const [appliedFilters, setAppliedFilters] = useState({})

  const openFiltersDialog = () => setFiltersDialogOpen(true)
  const closeFiltersDialog = () => setFiltersDialogOpen(false)

  const handleDeleteConfirmation = () => {
    submit(
      { intent: 'delete', id: markedForDel },
      { method: 'DELETE', encType: 'application/json' }
    )
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 2000)
  }

  const handleCancelConfirmation = () => {
    setDelConfirmationOpen(false)
    setMarkedForDel(null)
  }

  const handleDelete = (id) => {
    setDelConfirmationOpen(true)
    setMarkedForDel(id)
  }

  const handleApplyFilters = (activeFilters) => {
    closeFiltersDialog()
    submit(
      { intent: 'filters', activeFilters, initialFilters },
      { method: 'POST', encType: 'application/json' }
    )
  }

  const handleCloneLocation = ({ id, title }) => {
    setMarkedForClone({ id, name: title })
    setCloneConfirmationOpen(true)
  }

  const handleConfirmCloneLocation = async () => {
    const { id, name } = markedForClone
    submit(
      { intent: 'cloneLocation', id, entity: entityName, name },
      { method: 'post', encType: 'application/json' }
    )
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, 1500)
    })
  }

  const debouncedSearch = (request) => {
    debounce(handleApplyFilters(request), 400)
  }
    
  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `${capitalizeFirstLetter(entityName)} deleted successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if (searchParams.get('copied')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'copied') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `${capitalizeFirstLetter(entityName)} copied successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, entityName, searchParams, setSearchParams])

  useEffect(() => {
    if (!!initialFilters) {
      const applied = convertQueryStringToFilters(searchParams, initialFilters)
      const activeFiltersNumber = getActiveFiltersNumber(applied, initialFilters)
      setAppliedFilters(applied)
      setActiveFiltersNumber(activeFiltersNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, initialFilters])

  return (
    <>
      <ConfirmationDialog
        title={`Are you sure you want to delete the ${routeSegment}?`}
        message="You cannot undo this action."
        open={delConfirmationOpen}
        setOpen={setDelConfirmationOpen}
        onConfirm={handleDeleteConfirmation}
        onCancel={handleCancelConfirmation}
      />
      <ConfirmationDialog
        title={`Are you sure you want to copy this location?`}
        message="You will copy this location as is and will have to modify every detail after copying."
        open={cloneConfirmationOpen}
        setOpen={setCloneConfirmationOpen}
        onConfirm={handleConfirmCloneLocation}
        onCancel={handleCancelConfirmation}
      />
      <Suspense>
        <Await resolve={initialFilters}>
          {(initialFilters) => (
            <FiltersDialog
              open={filtersDialogOpen}
              initialFilters={initialFilters}
              appliedFilters={appliedFilters}
              searchParams={searchParams}
              onApply={handleApplyFilters}
              onClose={closeFiltersDialog}
            />
          )}
        </Await>
      </Suspense>
      <MDBox sx={{ margin: 'auto' }}>
        <Grid mt={6} container justifyContent="space-between">
          <Suspense fallback={<FiltersButtonSkeleton />}>
            <Await resolve={initialFilters}>
              {() => {
                if (initialFilters) {
                  const formInline = initialFilters.form === 'inline'
                  const handleFieldChange = (event) => {
                    const { name, value } = event.target
                    debouncedSearch({ [name]: value })
                  }
                  return formInline ? (
                    <Form
                      id={initialFilters.id}
                      {...initialFilters}
                      values={appliedFilters}
                      watchedFields={initialFilters.watchedFields}
                      onFieldChange={handleFieldChange}
                    />
                  ) : (
                    <FilterButton activeFiltersNumber={activeFiltersNumber}>
                      <MDButton
                        color="white"
                        onClick={openFiltersDialog}
                        sx={{
                          border: !!activeFiltersNumber
                            ? '2px solid grey'
                            : '2px solid transparent'
                        }}>
                        Filters &nbsp;<Icon>tunesharp</Icon>
                      </MDButton>
                    </FilterButton>
                  )
                }
                return <div></div>
              }}
            </Await>
          </Suspense>
          <Link to={`/dashboard/locations/${routeSegment}s/new`}>
            <MDButton color="primary">
              <Icon>add</Icon>&nbsp; Add {routeSegment}
            </MDButton>
          </Link>
        </Grid>
        {/* <Suspense fallback={<CardsSkeleton />}>
        <Await resolve={results}>
            {({ results, page_number }) => {
              console.log(results, page_number)
              return (
                <div>{results.length}</div>
              )
            }}
        </Await>
        </Suspense> */}
        <Suspense fallback={<CardsSkeleton />}>
          <Await resolve={results}>
            {({ results, page_number }) => {
              const pagination = {
                page: searchParams.get('page') || 1,
                lastPage: page_number
              }
              return (
                <>
                  <Grid
                    container
                    display="grid"
                    alignItems="start"
                    spacing={6}
                    sx={{ height: '100%', gridAutoRows: '1fr', mt: 2, gridTemplateColumns: results && results.length ? { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' } : 'repeat(1, 1fr)' }}>
                    {results?.length ? (
                        results.map((result) => {
                          const {
                            excerpt,
                            images,
                            featured_image,
                            name,
                            id,
                            address,
                            published_at
                          } = result
                          const mainImage = featured_image || getMainImage(images)
                          return (
                            <Grid display="grid" height="100%" item key={id}>
                              <LocationCard 
                                id={id}
                                publishedAt={published_at}
                                url={`${id}`}
                                image={mainImage}
                                title={name}
                                description={excerpt}
                                location={address || null}
                                canClone={['experience', 'accommodation'].includes(entityName)}
                                onDelete={handleDelete}
                                onClone={handleCloneLocation}
                              />
                            </Grid>
                          )
                        })
                      ) : (
                        <Grid item xs={12}>
                          <MDBox
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            height="50vh">
                            <MDBox
                              component="img"
                              src={noResults}
                              alt="no results"
                              width="100%"
                              maxHeight={200}
                              mb={3}
                            />
                            <MDTypography variant="h5">
                              No results found!
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      )
                    }
                  </Grid>
                  { pagination && results && !!results.length ? ( 
                    <Grid container justifyContent="center" marginTop={6}>
                      <Pagination
                        page={pagination.page || 1}
                        lastPage={pagination.lastPage}
                        searchParams={searchParams}
                      />
                    </Grid>
                  ) : null }
                </>
              )
            }}
          </Await>
        </Suspense>
      </MDBox>
     
    </>
  )
}

export default LocationListPage
