import { useState, useEffect, useCallback } from 'react'

import useBunnyCollection from 'hooks/useBunnyCollection'

import MDBox from 'components/MDBox'
import Icon from '@mui/material/Icon'
import MDTypography from 'components/MDTypography'
import VideoGalleryDialog from 'components/Dialogs/VideoGalleryDialog'
import VideoComponent from './VideoComponent'

const VideoUpload = ({
  value,
  label,
  collectionName,
  containerStyles = {},
  onChange,
  ...props
}) => {
  const [uploadLoading, setUploadLoading] = useState(false)
  const [video, setVideo] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { videos, isLoading, isError, getVideo, uploadVideo } = useBunnyCollection({
    collection: collectionName
  })

  const openDialog = () => {
    setDialogOpen(true)
  }
  const closeDialog = () => {
    setDialogOpen(false)
  }

  const handleOnChange = async (name, file, e) => {
    setUploadLoading(true)
    const { status, guid } = await uploadVideo({ file })
    setUploadLoading(false)
    if (status === 'success') {
      onChange({ target: { name, value: guid, needTouched: true } })
    }
  }

  const handleOnSelect = (video) => {
    onChange({ target: { name: props.name, value: video.guid, needTouched: true } })
  }

  const handleOnDelete = useCallback(() => {
    setVideo(null)
    onChange({ target: { name: props.name, value: '', needTouched: true } })
  }, [onChange, props.name])

  useEffect(() => {
    if (value && !value.startsWith('https://')) {
      const getExistingVideo = async () => {
        const video = await getVideo({ videoId: value })
        if (!video) {
          handleOnDelete()
          return
        }
        if (![4, 5, 6].includes(video.status)) {
          setTimeout(() => {
            getExistingVideo()
          }, 10000)
        }
        setVideo(video)
      }
      getExistingVideo()
    }
  }, [getVideo, handleOnDelete, value])

  return (
    <MDBox display="flex" justifyContent="center" my={3} flexDirection="column" alignItems="center">
      {label ? (
        <MDBox width="40%" minWidth={{ xs: '240px', md: "300px" }} maxWidth="450px" sx={containerStyles}>
          <MDTypography variant="button" fontWeight="regular" color="text" ml={0.5} mb={1}>
            {label}
          </MDTypography>
        </MDBox>
      ) : null}
      {video ? (
        <MDBox width="40%" minWidth={{ xs: '240px', md: "300px" }} maxWidth="450px" sx={containerStyles}>
          <VideoComponent video={video} editable onEdit={openDialog} onDelete={handleOnDelete} />
        </MDBox>
      ) : uploadLoading ? (
        <MDBox width="40%" minWidth={{ xs: '240px', md: "300px" }} maxWidth="450px" sx={containerStyles}>
          <VideoComponent video={{ status: 'CREATING' }} />
        </MDBox>
      ) : (
        <MDBox
          width="40%"
          minWidth={{ xs: '240px', md: "300px" }}
          maxWidth="450px"
          height="200px"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px dashed grey',
            borderRadius: 4,
            cursor: 'pointer',
            ...containerStyles
          }}
          onClick={openDialog}>
          <MDBox
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
            <MDTypography variant="body2">Add Video</MDTypography>
            <MDBox>
              <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
                video_camera_back_outlined
              </Icon>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
      <MDBox>
        <VideoGalleryDialog
          open={dialogOpen}
          videos={videos}
          selected={video}
          isLoading={isLoading}
          isError={isError}
          uploadProps={props}
          onVideoSelect={handleOnSelect}
          onVideoAdded={handleOnChange}
          onClose={closeDialog}
        />
      </MDBox>
    </MDBox>
  )
}

export default VideoUpload
