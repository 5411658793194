import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'
import { notify } from 'store/reducers/UISlice'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import AdditionalInformationDialog from 'components/Dialogs/AdditionalInformationDialog'
import AdditionalInformationCard from './AdditionalInformationCard'

import { CardsSkeleton } from 'components/Dialogs/Skeletons'

const AddCard = ({ title, icon, onAdd }) => {
  return (
    <Card sx={{ height: '100%', border: '1px dashed #7b809a' }}>
      <MDBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
        minHeight="180px"
        minWidth="120px"
        sx={{ cursor: 'pointer' }}
        onClick={onAdd}>
        <MDTypography variant="body2">{title}</MDTypography>
        <MDBox>
          <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
            {icon}
          </Icon>
        </MDBox>
      </MDBox>
    </Card>
  )
}

const OffsiteAdditionalInformations = ({ offsiteId }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [participants, setParticipants] = useState(null)
  const [participantsLoading, setParticipantsLoading] = useState(true)
  const [additionalInformations, setAdditionalInformations] = useState(null)
  const [additionalInformationDialogOpen, setAdditionalInformationDialogOpen] = useState(false)
  const [editingAdditionalInformation, setEditingAdditionalInformation] = useState({})

  const handleEditAdditionalInformation = (additionalInformation) => {
    setAdditionalInformationDialogOpen(true)
    setEditingAdditionalInformation(additionalInformation)
  }

  const handleAddAdditionalInformation = () => {
    setAdditionalInformationDialogOpen(true)
  }
  
  const handleSaveAdditionalInformation = async ({ confirmed, send_at, scheduled, ...additionalInformation }) => {
    return new Promise(async (resolve, reject) => {
      const { id } = editingAdditionalInformation
      setIsLoading(true)
      try {
        const values = {
          ...additionalInformation,
        }
        const response = await dispatch(smartwayApi.endpoints[id ? 'updateEntity' : 'createEntity'].initiate({
          entity: 'offsite',
          ...(id ? { id: offsiteId } : {}),
          action: `${id ? '' : `${offsiteId}/`}additional-infos/${id ? `${id}/` : ''}`,
          ...values
        }))
        if (response && response.data) {
          await fetchAdditionalInformations(offsiteId)
          notifyFeedback({ success: true })
        }
        resolve()
      } catch (error) {
        console.warn(error)
        notifyFeedback({ success: false })
        reject()
      } finally {
        setIsLoading(false)
        setAdditionalInformationDialogOpen(false)
        setEditingAdditionalInformation({})
      }
    })
  }


  const handleDeleteAdditionalInformation = ({ id, ...additionalInformation}) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true)
      try {
        const response = await dispatch(smartwayApi.endpoints.deleteEntity.initiate({
          entity: 'offsite',
          id: offsiteId,
          action: `additional-infos/${id}/`,
        }))
        if (response && response.data) {
          await fetchAdditionalInformations(offsiteId)
          notifyFeedback({ success: true })
        }
        resolve()
      } catch (error) {
        console.warn(error)
        notifyFeedback({ success: false })
        reject()
      } finally {
        setIsLoading(false)
        setAdditionalInformationDialogOpen(false)
        setEditingAdditionalInformation({})
      }
    })
  }

  const handleCloseAdditionalInformationDialog = () => {
    setAdditionalInformationDialogOpen(false)
    setEditingAdditionalInformation({})
  }

  const fetchAdditionalInformations = async (offsiteId) => {
    try {
      const additionalInformations = await dispatch(smartwayApi.endpoints.fetchAllEntities.initiate({
        offsiteId,
        entity: 'additional-infos',
        params: {
          page_size: 100
        }
      }))
      if (additionalInformations && additionalInformations.data) {
        setAdditionalInformations(additionalInformations.data)
      } else {
        setAdditionalInformations([])
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchParticipants = async () => {
    try {
      const participants = await dispatch(smartwayApi.endpoints.fetchAllEntities.initiate({
        offsiteId,
        entity: 'participants',
        params: {
          page_size: 100
        }
      }))
      console.log(participants)
      if (participants && participants.data && participants.data) {
        setParticipants(participants.data)
      } else {
        setParticipants([])
      }
    } catch (error) {
      console.warn(error)
    } finally {
      setParticipantsLoading(false)
    }
  }

  const notifyFeedback = ({ success }) => {
    if (success) {
      dispatch(
        notify({
          title: 'Well done!',
          message: 'Everything worked fine',
          type: 'success',
          icon: 'done'
        })
      )
    } else {
      dispatch(
        notify({
          title: 'Ops!',
          message: 'There was an error updating the information',
          type: 'error',
          icon: 'error'
        })
      )
    }
  }

  useEffect(() => {
    if (offsiteId) {
      if (!additionalInformations) {
        fetchAdditionalInformations(offsiteId)      
      }
    } else {
      setIsLoading(false)
      setAdditionalInformations([])
    }
  }, [offsiteId])

  useEffect(() => {
    if (additionalInformationDialogOpen && !participants) {
      fetchParticipants()
    }
  }, [additionalInformationDialogOpen])

  return (
    <MDBox sx={{
      marginLeft: '-12px',
      marginBottom: '-12px',
      marginRight: '12px',
      padding: '24px',
      backgroundColor: 'grey.100',
      borderRadius: '8px'
    }}>
      <MDBox mb={3}>
        <MDTypography variant="h5">Additional Informations</MDTypography>
        <MDTypography variant="body2">You can add any kind of information that does not fit elsewhere here. <br/> They will be visible in the app for everybody or only for the people you choose.</MDTypography>
      </MDBox>

      { isLoading ? <CardsSkeleton /> : (
        <Grid container rowSpacing={3} columnSpacing={3} pb={8}>
          <Grid item xs={12} sm={6} md={3}>
            <AddCard title="Add an information" icon="message" onAdd={handleAddAdditionalInformation} />
          </Grid>
          {additionalInformations ? [...additionalInformations].sort((a, b) => {
            return a.sent ? 1 : -1
          }).map((additionalInformation, index) => (
            <Grid key={`card-${index}`} item xs={12} sm={6} md={3}>
              <AdditionalInformationCard
                {...additionalInformation}
                onEdit={handleEditAdditionalInformation}
                onSave={handleSaveAdditionalInformation}
                onDelete={handleDeleteAdditionalInformation}
              />
            </Grid>
          )) : null }
          { !participantsLoading ? (
            <AdditionalInformationDialog
              open={additionalInformationDialogOpen}
              offsiteId={offsiteId}
              participants={participants}
              title="Additional information"
              additionalInformation={editingAdditionalInformation}
              onSave={handleSaveAdditionalInformation}
              onClose={handleCloseAdditionalInformationDialog}
            />
          ) : null }
        </Grid>
      ) }
    </MDBox>
  )
}

export default OffsiteAdditionalInformations
