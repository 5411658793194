import { useState } from 'react'
import Form from 'components/Form'
import getActivitySchema from 'components/Dialogs/schemas/activity'
import SimpleLocationCard from 'components/Cards/SimpleLocationCard'
import { LOCATION_ACTIVITY_TYPES } from 'utils/events'

import MDBox from 'components/MDBox'

import ActivityWorkshopCustomization from './ActivityWorkshopCustomization'
import { useActivities } from 'components/AgendaBuilder/useActivities'
import { ACTIVITY_NOTIFICATIONS, handleInitialSlotDuration } from 'utils/events'

const ActivityCustomization = ({
  id,
  offsiteId,
  agendaId,
  selectedActivity,
  values,
  participants,
  calendarSlot,
  activityType,
  onSubmit,
  onSaveSlot
}) => {
  const [schema, setSchema] = useState(getActivitySchema(values, activityType, participants))
  const { saveActivity } = useActivities({ agendaId })
  const custom_image =
    values && values.custom_image_formats ? { formats: values.custom_image_formats } : null

  const handleSaveActivityForCustomization = async (workshopValues) => {
    // we do not want to save the activity through the classic down/up flow
    // because we need to keep the modal opened through the workshop customization
    const slotWithRightDuration = handleInitialSlotDuration(
      { ...values, ...workshopValues },
      activityType,
      selectedActivity
    )
    const payload = {
      ...slotWithRightDuration,
      activityType,
      activity: selectedActivity
    }
    calendarSlot.updateSlot(payload)
    const formattedEvent = calendarSlot.formatToServer()
    const newActivity = await saveActivity(formattedEvent)
    if (!newActivity || !newActivity.slot) return null
    onSaveSlot(newActivity)
    return newActivity.slot
  }

  const handleUpdateSlot = (payload) => {
    calendarSlot.updateSlot(payload)
  }

  const handleFieldChange = (event, values, formik) => {
    const { name, value } = event.target
    if (name === 'participants') {
      let _value = value
      if (value.includes('all')) {
        if (value[value.length - 1] === 'all') {
          formik.setFieldValue(name, ['all'])
          _value = []
        } else {
          _value = _value.filter((v) => v !== 'all')
          formik.setFieldValue(name, _value)
        }
      }
      setSchema(getActivitySchema({ ...values, [name]: _value }, activityType, participants))
    }
  }

  const handleOnSubmit = (values) => {
    let _values = values
    if (values && values.participants && values.participants.includes('all')) {
      _values.participants = []
    }
    onSubmit(_values)
  }

  const initialValues = {
    ...values,
    custom_image,
    participants: values.participants && values.participants.length ? values.participants : ['all'],
    next_day: new Date(values.end).getDay() !== new Date(values.start).getDay(),
    ...(!values.activity && selectedActivity?.id ? {
      title: selectedActivity.name,
      description: selectedActivity.description.slice(0, 1000)
    } : {}),
    ...(values.custom_notification_title || values.custom_notification_body
      ? {}
      : {
          custom_notification_title: ACTIVITY_NOTIFICATIONS[activityType.type]?.title || '',
          custom_notification_body:
            ACTIVITY_NOTIFICATIONS[activityType.type]?.body.replace(
              '{title}',
              values.title ? values.title : selectedActivity.name ? selectedActivity.name : ''
            ) || ''
        })
  }

  return (
    <>
      {selectedActivity && LOCATION_ACTIVITY_TYPES.includes(activityType.name) ? (
        <SimpleLocationCard {...selectedActivity} />
      ) : null}
      <MDBox>
        <Form
          id={id}
          {...schema}
          values={initialValues}
          onFieldChange={handleFieldChange}
          onSubmit={handleOnSubmit}
        />
      </MDBox>
      {/** IN NO WAY A WORKSHOP COULD BE CUSTOMIZED IF NOT INSERTED IN AN OFFSITE */}
      {offsiteId && activityType.name === 'workshop' ? (
        <ActivityWorkshopCustomization
          offsiteId={offsiteId}
          values={values}
          onUpdateSlot={handleUpdateSlot}
          onCreateActivity={handleSaveActivityForCustomization}
        />
      ) : null}
    </>
  )
}

export default ActivityCustomization
