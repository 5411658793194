const schema = ({ room, bulk, entityName }) => {
  const distanceVisible = !!room.position_switch && entityName === 'accommodation'
  const bulkFieldset = bulk
    ? [
        {
          id: 'bulk',
          fields: [
            {
              type: 'number',
              name: 'roomNumber',
              label: 'Number of rooms you want to create',
              validationType: 'number',
              grid: { xs: 12, md: 4 },
              InputLabelProps: {
                shrink: true
              },
              helperText: 'Nb of rooms you want to create. Max. 100'
            }
          ]
        }
      ]
    : []

  const distanceFields =
    entityName === 'accommodation'
      ? [
          {
            type: 'switch',
            name: 'position_switch',
            label: 'Is the room outside the center?',
            validationType: 'boolean',
            validations: null,
            grid: { xs: 12, md: 4 }
          },
          {
            type: 'number',
            name: 'position',
            label: 'Distance from center (kilometers)',
            placeholder: 'Position',
            suffix: 'km',
            hidden: !distanceVisible,
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 4 }
          }
        ]
      : []

  const featuresFields =
    entityName === 'accommodation'
      ? [
          {
            type: 'checkboxgroup',
            name: 'features',
            label: 'Features',
            optionsGetter: {
              endpoints: [
                {
                  entity: 'locationFeatures',
                  id: 'room',
                  formatGroup: (options) => {
                    return options.reduce((acc, curr) => {
                      const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                      if (alreadyInAcc) {
                        alreadyInAcc.controls.push({
                          name: curr.id,
                          label: curr.name
                        })
                      } else {
                        acc.push({
                          label: curr.subtype,
                          controls: [{ name: curr.id, label: curr.name }]
                        })
                      }
                      return acc
                    }, [])
                  }
                }
              ]
            },
            validations: null
          }
        ]
      : [
          {
            type: 'checkboxgroup',
            name: 'features',
            label: 'Features',
            optionsGetter: {
              endpoints: [
                {
                  entity: 'locationFeatures',
                  id: 'all_in_venues',
                  formatGroup: (options) => {
                    return options.reduce((acc, curr) => {
                      const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                      if (alreadyInAcc) {
                        alreadyInAcc.controls.push({
                          name: curr.id,
                          label: curr.name
                        })
                      } else {
                        acc.push({
                          label: curr.subtype,
                          controls: [{ name: curr.id, label: curr.name }]
                        })
                      }
                      return acc
                    }, [])
                  }
                }
              ]
            },
            validations: null
          },
          {
            type: 'checkboxgroup',
            name: 'premium_features',
            label: 'Premium features',
            optionsGetter: {
              endpoints: [
                {
                  entity: 'locationPremiumFeatures',
                  formatGroup: (options) => {
                    return options.reduce((acc, curr) => {
                      const alreadyInAcc = acc.find((g) => g.label === curr.subtype)
                      if (alreadyInAcc) {
                        alreadyInAcc.controls.push({
                          name: curr.id,
                          label: curr.name
                        })
                      } else {
                        acc.push({
                          label: curr.subtype,
                          controls: [{ name: curr.id, label: curr.name }]
                        })
                      }
                      return acc
                    }, [])
                  }
                }
              ]
            },
            validations: null
          }
        ]

  return {
    id: 'room-form',
    name: 'room-form',
    fieldsets: [
      ...bulkFieldset,
      {
        id: 'avatar',
        title: bulk ? 'Bulk room creation' : 'Room informations',
        fields: [
          {
            type: 'text',
            name: 'title',
            label: 'Room title',
            required: true,
            placeholder: 'Enter room title',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Room description',
            placeholder: 'Room description',
            validationType: 'string',
            validations: []
          },
          {
            type: 'select',
            name: 'level',
            label: 'Room level',
            placeholder: 'Level',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            optionsGetter: {
              endpoints: [
                {
                  entity: 'roomLevels'
                }
              ]
            },
            grid: { xs: 12, md: 12 }
          },
          {
            type: 'select',
            name: 'category',
            label: 'Room category',
            placeholder: 'Category',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            optionsGetter: {
              endpoints: [
                {
                  entity: 'roomCategories'
                }
              ]
            },
            grid: { xs: 12, md: 6 }
          },
          {
            type: 'number',
            name: 'capacity',
            label: 'Full capacity',
            placeholder: 'Full capacity',
            validationType: 'string',
            validations: [],
            grid: { xs: 12, md: 3 }
          },
          {
            type: 'number',
            label: 'Bathrooms',
            name: 'bathrooms',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 3, mr: { xs: 0, md: 24 } }
          },
          {
            type: 'number',
            label: 'Min price',
            name: 'price_min',
            validationType: 'string',
            suffix: '€',
            validations: null,
            grid: { xs: 12, md: 3 },
            helperText: 'These prices will override the accommodation prices'
          },
          {
            type: 'number',
            label: 'Max price',
            name: 'price_max',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 3 }
          },
          {
            type: 'number',
            label: 'Normal price',
            name: 'normal_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 12, md: 3 }
          },
          ...distanceFields
        ]
      },
      {
        id: 'features',
        fields: [...featuresFields]
      }
    ]
  }
}

export default schema
