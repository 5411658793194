import { useMemo } from 'react'
import Form from 'components/Form'
import getFiltersSchema from './filters'
const RoomsFilters = ({ venueType, onChange }) => {
  const schema = useMemo(() => getFiltersSchema({ venueType }), [venueType])

  const handleFieldChange = (event) => {
    if (event.target.name === 'distance') {
      console.log('distance', event.target.value) 
    }
    onChange(event)
  }

  return (
    <Form
      {...schema}
      watchedFields={['search', 'level', 'category', 'empty', 'distance']}
      onFieldChange={handleFieldChange}
    />
  )
}

export default RoomsFilters
