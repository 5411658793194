import { useState, useRef } from 'react'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Card from '@mui/material/Card'

import RoomDialog from 'components/Dialogs/RoomDialog'
import DataTable from 'components/Tables/DataTable'
import RoomActionsCell from './RoomActionsCell'
import RoomBeds from 'components/RoomBeds'

import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import underConstruction from 'assets/illustrations/under-construction.svg'

const AccommodationRooms = ({
  entity,
  entityName = 'accommodation',
  rooms,
  onSave,
  onBulkAdd,
  onDelete
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedForDeletion, setSelectedForDeletion] = useState(null)
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)
  const [currentRoom, setCurrentRoom] = useState({})
  const isBulk = useRef(false)

  const roomsTable = {
    columns: [
      { Header: 'title', accessor: 'title' },
      { Header: 'category', accessor: 'category' },
      { Header: 'level', accessor: 'level' },
      { Header: 'capacity', accessor: 'capacity' },
      { Header: 'bathrooms', accessor: 'bathrooms' },
      { Header: 'price', accessor: 'price' }
    ],
    rows:
      rooms && !!rooms.length
        ? rooms.map((room) => {
            const { title, level, capacity, category, price_min, price_max, bathrooms } = room
            return {
              __data: room,
              title,
              category: <RoomBeds category={category} />,
              level,
              capacity,
              bathrooms,
              price: `${Number(price_min)}€ - ${Number(price_max)}€`
            }
          })
        : []
  }

  const openDialog = ({ position_switch, position, ...room }) => {
    setCurrentRoom({
      ...room,
      position_switch: !!position,
      position: position ? position / 1000 : 0
    })
    setDialogOpen(true)
  }
  const openNewDialog = () => {
    setCurrentRoom({})
    openDialog({})
  }
  const closeDialog = () => {
    setDialogOpen(false)
    setCurrentRoom({})
    isBulk.current = false
  }
  const closeConfirmationDialog = () => {
    setDelConfirmationOpen(false)
  }

  const handleSaveRoom = ({ position_switch, position, ...values }) => {
    closeDialog()
    const payload = {
      ...values,
      position: position_switch && position ? Number(position) * 1000 : 0
    }
    onSave({ entityId: entity.id, ...payload })
  }

  const handleBulkAdd = (values) => {
    closeDialog()
    onBulkAdd({ entityId: entity.id, ...values })
  }

  const handleDeleteRoom = (room) => {
    setDelConfirmationOpen(true)
    setSelectedForDeletion(room)
  }

  const handleDeleteRoomConfirmation = () => {
    setDelConfirmationOpen(false)
    onDelete({ entityId: entity.id, ...selectedForDeletion })
  }

  return (
    <Card>
      <ConfirmationDialog
        title="Are you sure you want to delete this room?"
        message="If this room is associated to an offsite you will not be able to delete it. You cannot undo this action."
        open={delConfirmationOpen}
        setOpen={setDelConfirmationOpen}
        onConfirm={handleDeleteRoomConfirmation}
        onCancel={closeConfirmationDialog}
      />
      <MDBox p={4}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2} flexWrap="wrap">
          <MDTypography variant="h5">Accommodation Rooms</MDTypography>
          <MDBox display="flex" gap={1}>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={() => {
                isBulk.current = true
                openNewDialog()
              }}>
              Bulk add rooms
            </MDButton>
            <MDButton variant="gradient" color="dark" size="small" onClick={openNewDialog}>
              Add room
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox>
          {rooms && rooms.length ? (
            <DataTable
              table={roomsTable}
              showTotalEntries={false}
              actionsOnRowHover={
                <RoomActionsCell
                  onDelete={(room) => handleDeleteRoom(room)}
                  onEdit={(room) => openDialog(room)}
                />
              }
            />
          ) : (
            <MDBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <MDBox
                component="img"
                src={underConstruction}
                alt="no results"
                width="100%"
                maxHeight={200}
                mb={3}
              />
              <MDTypography mb={2} variant="button" textAlign="center">
                There are no rooms yet in this accommodation! 
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
        <RoomDialog
          open={dialogOpen}
          bulk={isBulk.current}
          room={currentRoom}
          entityName={entityName}
          title={
            currentRoom && currentRoom.id
              ? currentRoom.title
              : isBulk.current
              ? 'Bulk add rooms'
              : 'Add Room'
          }
          onSave={isBulk.current ? handleBulkAdd : handleSaveRoom}
          onClose={closeDialog}
        />
      </MDBox>
    </Card>
  )
}

export default AccommodationRooms
