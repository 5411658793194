import Skeleton from '@mui/material/Skeleton'
import MDBox from 'components/MDBox'
export const OfferSkeleton = () => {

  return (
    <>
      <MDBox mt={2}>
        <Skeleton animation="wave" variant="rounded" height={32} width={60}></Skeleton>
        <MDBox
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          gap={2}
          pt={4}>
          <Skeleton animation="wave" variant="rounded" height={48} width="48%"></Skeleton>
          <Skeleton animation="wave" variant="rounded" height={48} width="48%"></Skeleton>
          <Skeleton animation="wave" variant="rounded" height={48} width={'48%'} style={{ marginTop: 24, marginRight: '100px' }}></Skeleton>
          <Skeleton animation="wave" variant="rounded" height={138} width={'100%'} style={{ marginTop: 24 }}></Skeleton>
          
          <MDBox display="flex" alignItems="center" gap={1} mt={8}>

            <Skeleton animation="wave" variant="rounded" height={20} width={20}></Skeleton>
            <Skeleton animation="wave" variant="rounded" height={20} width={156}></Skeleton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mt={8}>
        <Skeleton animation="wave" variant="rounded" height={32} width={180}></Skeleton>
        <MDBox
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          gap={2}
          pt={4}>
          <Skeleton animation="wave" variant="rounded" height={48} width="48%"></Skeleton>
          <Skeleton animation="wave" variant="rounded" height={48} width="48%"></Skeleton>
          <Skeleton animation="wave" variant="rounded" height={48} width={'100%'} style={{ marginTop: 24 }}></Skeleton>
          
          <MDBox display="flex" alignItems="center" gap={1} mt={8}>

            <Skeleton animation="wave" variant="rounded" height={20} width={20}></Skeleton>
            <Skeleton animation="wave" variant="rounded" height={20} width={156}></Skeleton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  )
}
