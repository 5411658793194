import { smartwayApi } from 'services/api'
const resolveFullOffsites = async ({ offsites, dispatch, needOffsiteData }) => {
  try {
    const venues = []
    const towns = []
    const companies = []
    let fullOffsites = []
  
    for (const offsite of offsites) {
      if (offsite.all_in_venue) {
        venues.push(offsite.all_in_venue)
      }
      if (offsite.town) {
        towns.push(offsite.town)
      }
      if (offsite.company) {
        companies.push(offsite.company)
      }
      if (needOffsiteData) {
        fullOffsites.push(offsite.id)
      }
    }
    const venuesPromise = Promise.all(
      Array.from(new Set(venues)).map((id) =>
        dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: 'allInVenue', id }))
      )
    )
    const townsPromise = Promise.all(
      Array.from(new Set(towns)).map((id) =>
        dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: 'town', id }))
      )
    )
    const companiesPromise = Promise.all(
      Array.from(new Set(companies)).map((id) =>
        dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: 'company', id }))
      )
    )
    const offsitesPromises = needOffsiteData
      ? Promise.all(
          Array.from(new Set(fullOffsites)).map((id) =>
            dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: 'offsite', id }))
          )
        )
      : Promise.resolve([])

      const [venuesRequest, townsRequest, companiesRequest, offsitesRequest] = await Promise.all([
        venuesPromise,
        townsPromise,
        companiesPromise,
        offsitesPromises
      ])
      let venuesData = []
      let townsData = []
      let companiesData = []
      let offsitesData = []
    
      venuesRequest.forEach((venue) => {
        if (venue.status === 'fulfilled' && venue.data) {
          venuesData.push(venue.data)
        }
      })
      townsRequest.forEach((town) => {
        if (town.status === 'fulfilled' && town.data) {
          townsData.push(town.data)
        }
      })
      companiesRequest.forEach((company) => {
        if (company.status === 'fulfilled' && company.data) {
          companiesData.push(company.data)
        }
      })
      const offsiteCompanies = [] 
      offsitesRequest.forEach((offsite) => {
        if (offsite.status === 'fulfilled' && offsite.data) {
          if (offsite.data.company && typeof offsite.data.company === 'number') {
            offsiteCompanies.push(offsite.data.company)
          }
          offsitesData.push(offsite.data)
        }
      })
    
      if (!!offsiteCompanies.length) {
        const newCompanies = await Promise.all(
          Array.from(new Set(offsiteCompanies)).map((id) =>
            dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: 'company', id }))
          )
        ) 
        newCompanies.forEach((company) => {
          if (company.status === 'fulfilled' && company.data) {
            companiesData.push(company.data)
          }
        })
      }
    
      return offsites.map((offsite) => {
        const fullOffsiteData = needOffsiteData ? offsitesData.find(({ id }) => id === offsite.id) : offsite
        const { id, all_in_venue, town, company } = fullOffsiteData
        const venue = all_in_venue
          ? venuesData.find(({ id }) => id === all_in_venue)
          : townsData.find(({ id }) => id === town)
        const _company = companiesData.find((_company) => _company.id === company)
    
        return {
          id,
          ...offsite,
          ...fullOffsiteData,
          venue,
          company: _company,
        }
      })
  } catch (error) {
    console.warn(error)
    return null
  }



  
}

const saveParticipantPreferences = async ({ payload, dispatch, context = 'profile' }) => {
  const {
    id,
    participantId,
    user,
    mobile,
    room_sharing,
    roommates,
    roommates_string,
    transfer,
    transfer_return,
    transfer_from,
    transfer_to,
    arrival_date,
    departure_date,
    arrival_reservation_number,
    departure_reservation_number,
    special_needs,
    dietary_options,
    dietary_options_other,
    allergies,
    allergies_other,
    customs,
    participantInfos = {},
    profilePreferencesMethod
  } = payload

  const { touched, ..._participantInfos } = participantInfos

  const method = profilePreferencesMethod === 'patch' ? 'updateEntity' : 'createEntity'
  const userMethod = user?.user_preferences ? 'updateEntity' : 'createEntity'
  const prop = profilePreferencesMethod === 'patch' ? 'id' : 'action'
  const userProp = user?.user_preferences ? 'id' : 'action'
  const responses = await Promise.all([
    dispatch(
      smartwayApi.endpoints[method].initiate({
        entity: 'offsite',
        ...(method === 'updateEntity' ? { id } : {}),
        ...(method === 'updateEntity'
          ? { action: `participants/${participantId}/preferences/` }
          : { action: `${id}/participants/${participantId}/preferences/` }),
        room_sharing,
        roommates,
        roommates_string:
          roommates_string && typeof roommates_string === 'string'
            ? roommates_string.split('|')
            : roommates_string,
        transfer,
        transfer_return,
        transfer_from,
        transfer_to,
        arrival_date,
        departure_date,
        arrival_reservation_number,
        departure_reservation_number,
        special_needs,
        customs
      })
    ),
    ...(participantInfos && touched && context !== 'profile'
      ? [
          dispatch(
            smartwayApi.endpoints.updateEntity.initiate({
              entity: 'offsite',
              id,
              action: `participants/${participantId}/`,
              ..._participantInfos
            })
          )
        ]
      : []),
    ...(mobile
      ? [
          dispatch(
            smartwayApi.endpoints.updateEntity.initiate(
              ...(context === 'profile'
                ? [{ entity: 'profile', id: 'me', mobile }]
                : [{ entity: 'user', id: user?.user_id, mobile }])
            )
          )
        ]
      : []),
    ...(context === 'profile'
      ? [
          dispatch(
            smartwayApi.endpoints[method].initiate(
              {
                entity: 'profile',
                [prop]: 'me/preferences/',
                dietary_options,
                allergies,
                allergies_other,
                dietary_options_other
              },
              { forceRefetch: true }
            )
          )
        ]
      : user ? [
        dispatch(
          smartwayApi.endpoints[userMethod].initiate(
            {
              entity: 'user',
              [userProp]: `${user?.user_id}/preferences${userProp === 'action' ? '/' : ''}`,
              dietary_options,
              allergies,
              allergies_other,
              dietary_options_other
            },
            { forceRefetch: true }
          )
        )
      ] : [])
  ])
  return responses
}


const transformOffsitePayloadToPost = (payload) => {
  const excludedProps = ['id', 'slug', 'agendas', 'updated_at', 'created_at', 'rooms', 'participants', 'featured_image']
  const newOffsitePayload = {}
  for (const [key, value] of Object.entries(payload)) {
    if (!excludedProps.includes(key)) {
      newOffsitePayload[key] = value
    }
  }
  if (newOffsitePayload.start_at && newOffsitePayload.end_at) {
    delete newOffsitePayload.indicative_number_days  
  } else {
    delete newOffsitePayload.start_at
    delete newOffsitePayload.end_at
  }
  if (newOffsitePayload.town) {
    delete newOffsitePayload.all_in_venue
  } else {
    delete newOffsitePayload.town
  }
  if (newOffsitePayload.managed_by && !!newOffsitePayload.managed_by.length) {
    newOffsitePayload.managed_by = newOffsitePayload.managed_by.map(manager => {
      return {
        role: manager.role,
        user: manager.user.user_id
      }
    })
  }
  if (newOffsitePayload.collaborators) {
    newOffsitePayload.collaborators = newOffsitePayload.collaborators.map((c) => c.id)
  }
  return newOffsitePayload
}

export { resolveFullOffsites, saveParticipantPreferences, transformOffsitePayloadToPost }
