import { differenceInDays } from 'date-fns'
import { ACTIVITY_TYPES } from "./events"

export const DEFAULT_PRICE_PER_NIGHT = 120

export const DEFAULT_TRANSFER_PRICE = 50

export const DEFAULT_TAKE_RATE = 0.35

export const DEFAULT_LUNCH_PRICE = 35

export const DEFAULT_DINNER_PRICE = 45

export const DEFAULT_BREAKFAST_PRICE = 10

export const DEFAULT_EXPERIENCE_PRICE = 30

export const DEFAULT_COWORKING_PRICE = 20


const getNumberOfDaysWithBusinessActivities = (businessActivities) => {
  const nbOfDaysWithBusinessActivities = businessActivities.reduce((acc, activity) => {
    const day = new Date(activity.start_at).getDate()
    if (!acc.includes(day)) {
      acc.push(day)
    }
    return acc
  }, []).length

  return nbOfDaysWithBusinessActivities
}

const getDefaultLocationPrice = (type, start_at, prices) => {
  if (type === 'FOOD_DRINK') {
    const hour = new Date(start_at).getHours()
    if (hour >= 12 && hour <= 17) {
      return prices?.lunch_price || DEFAULT_LUNCH_PRICE
    }
    if (hour >= 18 && hour <= 22) {
      return prices?.dinner_price || DEFAULT_DINNER_PRICE
    }
    return prices?.breakfast_price || DEFAULT_BREAKFAST_PRICE
  }
  return prices?.experience_price || DEFAULT_EXPERIENCE_PRICE
}

const getNumberOfDays = (offsite, nb_days) => {
  let nbOfDays = nb_days
  if (offsite && !!Object.keys(offsite).length) {
    const { start_at, end_at, indicative_number_days } = offsite
    nbOfDays = differenceInDays(new Date(end_at), new Date(start_at)) + 1 
    if (indicative_number_days) {
      nbOfDays = indicative_number_days
    }
  }
  return nbOfDays > 0 ? nbOfDays : 0
}


const generateAgendaPrice = ({ agenda, prices = {}, offsite }) => {
  const { activities, nb_days } = agenda
  const needDefaultPrices = {}
  const isBusinessActivities = activities.some(activity => activity.type === 'BUSINESS_ACTIVITY')
  const activitiesPrices = activities.filter(a => a.type !== 'BUSINESS_ACTIVITY').reduce((acc, activity) => {
    const activityType = ACTIVITY_TYPES.find(type => type.type === activity.type)
    if (activityType.isLocation) {
      const { start_at, location } = activity
      // const { normal_price } = location
      const price = getDefaultLocationPrice(activity.type, start_at, prices)
      needDefaultPrices[activityType.type] = true
      if (acc[activityType.type]) {
        acc[activityType.type] += price
      } else {
        acc[activityType.type] = price
      }
    }
    if (activityType.type === 'TRANSFER') {
      const transferPrice = prices?.transfer_price || DEFAULT_TRANSFER_PRICE
      if (!prices?.transfer_price) {
        needDefaultPrices.TRANSFER = true
      }
      if (acc.TRANSFER) {
        acc.TRANSFER += transferPrice
      } else {
        acc.TRANSFER = transferPrice
      }
    }
    return acc
  }, {})


  const nbOfDays = getNumberOfDays(offsite, nb_days)
  const nbOfNights = nbOfDays === 0 ? 0 : nbOfDays - 1
  const nbOfDaysWithBusinessActivities = getNumberOfDaysWithBusinessActivities(activities)
  const accommodationsPrice = nbOfNights * (prices?.price_per_night || DEFAULT_PRICE_PER_NIGHT)
  const businessActivitiesPrice = isBusinessActivities ? (prices?.coworking_price || DEFAULT_COWORKING_PRICE) * nbOfDaysWithBusinessActivities : 0
  const { TRANSFER, EXPERIENTIAL_ACTIVITY, FOOD_DRINK } = activitiesPrices
  const allActivitiesPrice = (TRANSFER || 0) + (EXPERIENTIAL_ACTIVITY || 0) + (FOOD_DRINK || 0)

  const subtotal = allActivitiesPrice + accommodationsPrice
  const takeRate = subtotal * (prices?.take_rate ? (prices.take_rate / 100) : DEFAULT_TAKE_RATE)
  const total = Math.ceil(subtotal + takeRate)
  // console.table({allActivitiesPrice,  FOOD_DRINK, EXPERIENTIAL_ACTIVITY, TRANSFER, accommodationsPrice, subtotal, takeRate, total})
  return { total, activitiesPrices, accommodationsPrice, businessActivitiesPrice, takeRate, nbOfDaysWithBusinessActivities, nbOfDays, nbOfNights, needDefaultPrices }
}

export { generateAgendaPrice }