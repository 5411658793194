import { useState, useRef } from 'react'
import {
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'

import Form from 'components/Form/Form'

import getSchema from './schemas/agenda-price'
import {
  generateAgendaPrice,
  DEFAULT_PRICE_PER_NIGHT,
  DEFAULT_BREAKFAST_PRICE,
  DEFAULT_LUNCH_PRICE,
  DEFAULT_DINNER_PRICE,
  DEFAULT_EXPERIENCE_PRICE,
  DEFAULT_COWORKING_PRICE,
  DEFAULT_TRANSFER_PRICE,
  DEFAULT_TAKE_RATE
} from 'utils/agenda'
import MDTypography from 'components/MDTypography/index'

const initialValues = {
  price_per_night: DEFAULT_PRICE_PER_NIGHT,
  breakfast_price: DEFAULT_BREAKFAST_PRICE,
  lunch_price: DEFAULT_LUNCH_PRICE,
  dinner_price: DEFAULT_DINNER_PRICE,
  experience_price: DEFAULT_EXPERIENCE_PRICE,
  coworking_price: DEFAULT_COWORKING_PRICE,
  transfer_price: DEFAULT_TRANSFER_PRICE,
  take_rate: DEFAULT_TAKE_RATE * 100
}

const RecapPriceLine = ({ label, price, isZero, isTotal }) => {
  const formatPrice = (price) => {
    return Number(price).toFixed(2)
  }

  return (
    <MDBox
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      color={isZero ? 'lightgrey !important' : ''}
      {...(isTotal ? { mt: 2, pt: 2, sx: { borderTop: '1px solid #7b809a' } } : {})}>
      <MDTypography
        variant={isTotal ? 'h5' : 'body2'}
        fontWeight={isTotal ? 'bold' : 'regular'}
        color={isZero ? 'lightgrey !important' : ''}>
        {label}
      </MDTypography>
      <MDTypography
        variant={isTotal ? 'h5' : 'body2'}
        fontWeight={isTotal ? 'bold' : 'regular'}
        color={isZero ? 'lightgrey !important' : ''}>
        {formatPrice(price)} € {isTotal ? '/ person' : ''}
      </MDTypography>
      {/* {isZero ? (
        <MDBox
          position="absolute"
          top="50%"
          right="0"
          width="100%"
          height="1px"
          bgColor="lightgrey"
          zIndex={1}></MDBox>
      ) : null} */}
    </MDBox>
  )
}

const AgendaPriceDialog = ({ open, agenda, offsite, title, onSave, onClose }) => {
  const getRecapPriceArray = ({ suggestedPrices, defaultPrices }) => {
    return [
      {
        label: `Accommodations: ${suggestedPrices.nbOfNights} night${
          suggestedPrices.nbOfNights > 1 ? 's' : ''
        } x ${defaultPrices.price_per_night} €`,
        price: suggestedPrices.nbOfNights * defaultPrices.price_per_night,
        isZero: suggestedPrices.nbOfNights <= 0
      },
      {
        label: `Experiential activities (exp. and workshops)`,
        price: suggestedPrices.activitiesPrices.EXPERIENTIAL_ACTIVITY || 0,
        isZero: !suggestedPrices.activitiesPrices.EXPERIENTIAL_ACTIVITY
      },
      {
        label: `Business activities: ${suggestedPrices.nbOfDaysWithBusinessActivities} day${
          suggestedPrices.nbOfDaysWithBusinessActivities > 1 ? 's' : ''
        } x ${defaultPrices.coworking_price} €`,
        price: suggestedPrices.businessActivitiesPrice || 0,
        isZero: !suggestedPrices.businessActivitiesPrice
      },
      {
        label: 'Food & drink',
        price: suggestedPrices.activitiesPrices.FOOD_DRINK || 0,
        isZero: !suggestedPrices.activitiesPrices.FOOD_DRINK
      },
      {
        label: 'Transfers',
        price: suggestedPrices.activitiesPrices.TRANSFER || 0,
        isZero: !suggestedPrices.activitiesPrices.TRANSFER
      },
      {
        label: `Take rate (${defaultPrices.take_rate}%)`,
        price: suggestedPrices.takeRate || 0,
        isZero: !suggestedPrices.takeRate
      },
      {
        label: `TOTAL`,
        price: suggestedPrices.total,
        isZero: false,
        isTotal: true
      }
    ]
  }

  const [defaultPrices, setDefaultPrices] = useState(initialValues)
  const initialSuggestedPrices = useRef(
    generateAgendaPrice({ agenda, prices: initialValues, offsite })
  )
  const [suggestedPrices, setSuggestedPrices] = useState(initialSuggestedPrices.current)
  const [recapPricesArray, setRecapPricesArray] = useState(
    getRecapPriceArray({ suggestedPrices, defaultPrices })
  )

  const schema = getSchema({ suggestedPrices })
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      setDefaultPrices(initialValues)
      setSuggestedPrices(generateAgendaPrice({ agenda, prices: initialValues, offsite }))
      onClose()
    }
  }

  const handleOnSave = () => {
    if (onSave && typeof onSave === 'function') {
      const { total } = suggestedPrices
      onSave(total)
    }
  }

  const handleFieldChange = (event) => {
    const { name, value } = event.target
    const newDefaultPrices = {
      ...defaultPrices,
      [name]: Number(value)
    }
    const newSuggestedPrices = generateAgendaPrice({ agenda, prices: newDefaultPrices, offsite })
    setSuggestedPrices(newSuggestedPrices)
    setDefaultPrices(newDefaultPrices)
    setRecapPricesArray({ suggestedPrices: newSuggestedPrices, defaultPrices: newDefaultPrices })
  }

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox display="flex" alignItems="flex-end" height="100%" pt={2}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  p={4}
                  gap={1}
                  sx={{ backgroundColor: '#f0f2f5', borderRadius: '4px' }}>
                  {recapPricesArray.map((priceLine, index) => {
                    return <RecapPriceLine key={`price-line-${index}`} {...priceLine} />
                  })}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Form
                {...schema}
                onFieldChange={handleFieldChange}
                values={defaultPrices}
                onSubmit={handleOnSave}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton type="submit" form="agenda-price-form" variant="contained" color="primary">
            Set price
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default AgendaPriceDialog
