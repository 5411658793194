import { useState, useEffect, useCallback } from 'react'
import { utils, writeFile } from 'xlsx'

import DataTable from 'components/Tables/DataTable'
import { removeDuplicates, stripTags } from 'utils/functions'
import { getValueLabel, getCustomValueLabel } from 'utils/preferences'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'
import MDButton from 'components/MDButton'

const ParticipantsExportDialog = ({
  open,
  offsite,
  title,
  settings,
  options,
  participants,
  onClose
}) => {
  const [isExporting, setIsExporting] = useState(false)
  const [participantsList, setParticipantsList] = useState(removeDuplicates(participants, 'emailq'))
  const [participantsTable, setParticipantsTable] = useState([])

  const createTableFromList = useCallback((list, settings) => {
    const personalInfosFieldset = settings?.fieldsets.find((f) => f.name === 'personal_infos')
    const isPersonalInfos = personalInfosFieldset.active

    const roomSharingFieldset = settings?.fieldsets.find((f) => f.name === 'room_sharing')
    const isRoomSharing = roomSharingFieldset.active

    const transferFieldset = settings?.fieldsets.find((f) => f.name === 'transfer')
    const isTransfer = transferFieldset.active

    const transferDetailsFieldset = settings?.fieldsets.find((f) => f.name === 'transfer_details')
    const isTransferDetails = transferDetailsFieldset.active

    const roommatesFieldset = settings?.fieldsets.find((f) => ['roommates_string', 'roommates'].includes(f.name))
    const isRoommates = roommatesFieldset.active

    const customsQuestions = settings?.custom_fields

    return {
      columns: [
        { Header: 'firstname', accessor: 'first_name' },
        { Header: 'lastname', accessor: 'last_name' },
        { Header: 'email', accessor: 'email' },
        { Header: 'phone', accessor: 'phone' },
        { Header: 'status', accessor: 'status' },
        ...(isPersonalInfos
          ? [
              { Header: 'special needs', accessor: 'special_needs' },
              { Header: 'dietary options', accessor: 'dietary_options' },
              { Header: 'food allergies', accessor: 'allergies' }
            ]
          : []),
        ...(isRoomSharing ? [{ Header: 'room sharing', accessor: 'room_sharing' }] : []),
        ...(isRoommates ? [{ Header: 'roommates', accessor: 'roommates' }] : []),
        ...(isTransfer
          ? [
              { Header: 'transfer', accessor: 'transfer' },
              ...(transferFieldset.fields.find((f) => f.name === 'transfer_return') ? [
                { Header: 'transfer (return)', accessor: 'transfer_return' }
              ] : [])
            ]
          : []),
        ...(isTransferDetails
          ? [
              { Header: 'transfer from', accessor: 'transfer_from' },
              { Header: 'transfer to', accessor: 'transfer_to' },
              { Header: 'arrival date', accessor: 'arrival_date' },
              { Header: 'departure date', accessor: 'departure_date' },
              { Header: 'arrival reservation number', accessor: 'arrival_reservation_number' },
              { Header: 'departure reservation number', accessor: 'departure_reservation_number' }
            ]
          : []),
        ...(customsQuestions && customsQuestions.length
          ? customsQuestions.map((q) => ({ Header: q.title, accessor: q.field_key }))
          : [])
      ],
      rows: list.map((participant) => {
        const {
          first_name,
          last_name,
          email,
          confirmation,
          last_invited_at,
          replied_at,
          user,
          offsite_preference
        } = participant

        const userPreferences = {
          ...(offsite_preference ? offsite_preference : {}),
          ...(user?.user_preferences ? user.user_preferences : {})
        }

        const getStatus = () => {
          let status = []
          if (!last_invited_at) {
            status.push('Not invited')
          }
          if (confirmation !== null && replied_at) {
            status.push(confirmation ? 'Confirmed' : 'Declined')
          }
          if (user) {
            status.push('Registered')
          }
          if (last_invited_at && !user) {
            status.push('Invited - Not registered')
          }
          return status.join(' | ')
        }

        return {
          __data: participant,
          first_name,
          last_name,
          email,
          phone: user ? user.mobile : '',
          status: getStatus(),
          ...(isPersonalInfos
            ? {
                special_needs: stripTags(userPreferences.special_needs),
                dietary_options: `
              ${getValueLabel({
                field: personalInfosFieldset.fields.find((f) => f.name === 'dietary_options'),
                options,
                userPreferences
              })}
              ${
                userPreferences.dietary_options_other
                  ? ` - ${userPreferences.dietary_options_other}`
                  : ''
              }
            `,
                allergies: `
              ${getValueLabel({
                field: personalInfosFieldset.fields.find((f) => f.name === 'allergies'),
                options,
                userPreferences
              })}
              ${userPreferences.allergies_other ? ` - ${userPreferences.allergies_other}` : ''}
            `
              }
            : {}),
          ...(isRoomSharing
            ? {
                room_sharing: getValueLabel({
                  field: roomSharingFieldset.fields.find((f) => f.name === 'room_sharing'),
                  options,
                  userPreferences
                })
              }
            : {}),
          ...(isRoommates
            ? {
                roommates: getValueLabel({
                  field: roommatesFieldset.fields.find((f) => ['roommates_string', 'roommates'].includes(f.name)),
                  options,
                  userPreferences
                })
              }
            : {}),
          ...(isTransfer
            ? {
                transfer: getValueLabel({
                  field: transferFieldset.fields.find((f) => f.name === 'transfer'),
                  options,
                  userPreferences
                }),
                ...(transferFieldset.fields.find((f) => f.name === 'transfer_return') ? {
                  transfer_return: getValueLabel({
                    field: transferFieldset.fields.find((f) => f.name === 'transfer_return'),
                    options,
                    userPreferences
                  }),
                } : {})
              }
            : {}),
          ...(isTransferDetails
            ? {
                transfer_from: getValueLabel({
                  field: transferDetailsFieldset.fields.find((f) => f.name === 'transfer_from'),
                  options,
                  userPreferences
                }),
                transfer_to: getValueLabel({
                  field: transferDetailsFieldset.fields.find((f) => f.name === 'transfer_to'),
                  options,
                  userPreferences
                }),
                arrival_date: getValueLabel({
                  field: transferDetailsFieldset.fields.find((f) => f.name === 'arrival_date'),
                  options,
                  userPreferences
                }),
                departure_date: getValueLabel({
                  field: transferDetailsFieldset.fields.find((f) => f.name === 'departure_date'),
                  options,
                  userPreferences
                }),
                arrival_reservation_number: getValueLabel({
                  field: transferDetailsFieldset.fields.find(
                    (f) => f.name === 'arrival_reservation_number'
                  ),
                  options,
                  userPreferences
                }),
                departure_reservation_number: getValueLabel({
                  field: transferDetailsFieldset.fields.find(
                    (f) => f.name === 'departure_reservation_number'
                  ),
                  options,
                  userPreferences
                })
              }
            : {}),
          ...(customsQuestions && customsQuestions.length
            ? customsQuestions.reduce((acc, curr) => {
                acc[curr.field_key] = getCustomValueLabel({ field: curr, userPreferences })
                return acc
              }, {})
            : {})
        }
      })
    }
  }, [])

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  const formatParticipantsListToExcel = (participants) => {
    const { columns, rows } = createTableFromList(participants, settings)

    const firstSheet = rows.map((row) => {
      return columns.reduce((acc, curr) => {
        // Don't know why, but I can't manage to make the Regex work - if you want to try -> /\/n|\\n/gi
        const content = row[curr.accessor]
        const stringContent = Array.isArray(content) ? content.join(', ') : content
        acc[curr.Header] = stringContent
          ? stringContent.replaceAll('\n', '').replaceAll('\n', '').replaceAll('  ', '').trim()
          : ''
        return acc
      }, {})
    })

    return {
      sheets: [
        {
          title: 'Participants',
          data: firstSheet
        }
        // { title: 'Roommates', data: secondSheet }
      ]
    }
  }

  const handleOnExport = () => {
    setIsExporting(true)
    const a = document.createElement('a')
    a.style = 'display: none'

    const { sheets } = formatParticipantsListToExcel(participantsList)

    document.body.appendChild(a)

    const wb = utils.book_new()

    sheets.forEach((_sheet) => {
      const { title, data, merges } = _sheet
      const sheet = utils.json_to_sheet(data)
      if (merges && merges.length) {
        sheet['!merges'] = merges
      }
      const MIN_COL_WIDTH = 150
      sheet['!cols'] = Object.keys(data[0]).map((k) => ({ wpx: MIN_COL_WIDTH }))
      utils.book_append_sheet(wb, sheet, title)
    })

    writeFile(wb, `Participants-list_Offsite-${offsite.title}.xlsx`)
    document.body.removeChild(a)
    setIsExporting(false)
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  useEffect(() => {
    if (open) {
      setParticipantsTable(createTableFromList(participantsList, settings))
    }
  }, [open, createTableFromList, settings, participantsList])

  useEffect(() => {
    if (open) {
      setParticipantsList(removeDuplicates(participants, 'email'))
    }
  }, [open, participants])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent" sx={{ pt: 0, overflow: 'hidden' }}>
          {participantsTable && !!participantsTable.rows && !!participantsTable.rows.length ? (
            <DataTable canSearch table={participantsTable} />
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <LoadingButton
            disabled={isExporting}
            loading={isExporting}
            variant="contained"
            sx={{
              backgroundColor: 'error.main',
              color: 'white !important',
              '&:hover': {
                backgroundColor: 'error.light'
              }
            }}
            onClick={handleOnExport}>
            <Icon sx={{ mr: 1 }}>download</Icon> Export
          </LoadingButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default ParticipantsExportDialog
